//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LayoutSubpage',
  data () {
    return {
      leftDrawerOpen: false
    }
  },
  methods: {
    back: function () {
      this.$router.go(-1)
    }
  }
}
