//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Betslip from './Betslip'

export default {
  name: 'BetslipTab',
  components: {
    Betslip
  },
  data () {
    return {
      tab: 'single'
    }
  },
  computed: {
    single: {
      get: function () {
        return this.$store.state.betslip2.single
      },
      set: function (single) {
        console.log("set single: " + JSON.stringify(single))
        this.$store.commit('betslip2', { single })
      }
    },
    parlay: {
      get: function () {
        return this.$store.state.betslip2.parlay
      },
      set: function (parlay) {
        console.log("set paylay: " + JSON.stringify(parlay))
        this.$store.commit('betslip2', { parlay })
      }
    },
    step: {
      get: function () {
        return this.$store.state.betslip2.step
      },
      set: function (step) {
        console.log("set step: " + JSON.stringify(step))
        this.$store.commit('betslip2', { step })
      }
    }
  }
}
