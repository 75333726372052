//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UserInfo',
  data () {
    return {
      loading: false,
      showBalance: true,
      openUserDetails: false
    }
  },
  computed: {
    user: function () {
      return this.$store.state.user
    }
  },
  methods: {
    async refresh () {
      this.loading = true
      this.$store.dispatch('getUser').then(() => this.loading = false)
    },
  }
}
