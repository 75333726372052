/* eslint-disable no-empty-pattern */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import sha1 from 'js-sha1'
import local from '../local'

Vue.use(Vuex);

// axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? 'https://api.wesport88.com' : 'http://localhost:8080'
axios.defaults.baseURL = 'https://api.wesport88.com'
axios.defaults.headers.post['Content-Type'] = 'application/json';

axios.interceptors.request.use(config => {
    var locale = localStorage.getItem("locale")
    config.headers['Locale'] = locale ? locale : 'en_US'
    config.headers['Accept-Odds-Type'] = localStorage.getItem("oddsType")

    let token = localStorage.getItem("Authorization")
    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token
    }

    return config
},
    error => {
        return Promise.reject(error)
    });

axios.interceptors.response.use(response => response, error => {
    console.log(error)
    try {
        if (401 === error.response.status) {
            if (local.get('Authorization')) {
                local.remove('Authorization')
                local.remove('loggedOn')
                alert("Your session has been terminated.")
                location.href = "/"
            }
        }
    } catch (e) {
        //
    }

    return Promise.reject(error)
})

export default new Vuex.Store({
    state: {
        name: 'Sportsbook',
        token: local.get('Authorization'),
        locale: local.get('locale'),
        loggedOn: local.get('loggedOn') === 'true',
        active: 'all-sports',
        activeFavorites: false,
        user: {
            userId: 0,
            username: '',
            loginId: '',
            currency: 'HKD',
            cashBalance: 0.00,
            outstanding: 0.00,
            netPosition: 0.00,
            betCredit: 0.00,
            givenCredit: 0.00,
            lastLogin: null,
            lastTransaction: null
        },
        profiles: {
            oddsType: local.get('oddsType') || 'DEC',
            oddsView: local.get('oddsView') || 'dobule',
            sort: local.get('oddsSort') || 1,
            selectedLeagues: [],
            favorites: []
        },
        sports: [],
        betslip: null,
        betlist: 0,
        betChecking: false,
        err: null,
        sb: {
            current: 'today',
            sports: [],
            selectedSports: null,
            activeOdds: null
        },
        betslip2: {
            single: { type: 1, selections: [] },
            parlay: { type: 2, selections: [] },
            step: { type: 3, selections: [] }
        },
        title: null,
        rememberMe: local.get('rememberMe') ? JSON.parse(local.get('rememberMe')) : { loginId: '', rememberMe: true },
        stake: ''
    },
    mutations: {
        name: (state, name) => state.name = name,
        locale: (state, locale) => {
            state.locale = locale
            localStorage.setItem("locale", locale)
        },
        token: (state, token) => {
            state.token = token

            if (token) {
                localStorage.setItem("Authorization", token)
            }
            else {
                localStorage.removeItem("Authorization")
            }
        },
        loggedOn: (state, loggedOn) => {
            state.loggedOn = loggedOn
            localStorage.setItem("loggedOn", loggedOn)
        },
        activeFavorites: (state, activeFavorites) => state.activeFavorites = activeFavorites,
        active: (state, active) => state.active = active,
        user: (state, user) => state.user = user,
        profiles: (state, profiles) => {
            state.profiles = Object.assign(state.profiles, profiles)
            if (profiles.oddsType)
                localStorage.setItem("oddsType", profiles.oddsType)
            if (profiles.oddsView)
                localStorage.setItem("oddsView", profiles.oddsView)
            if (profiles.sort)
                localStorage.setItem("oddsSort", profiles.sort)
        },
        sports: (state, sports) => state.sports = sports,
        betslip: (state, betslip) => state.betslip = betslip,
        betlist: (state, betlist) => state.betlist = betlist,
        betChecking: (state, betChecking) => state.betChecking = betChecking,
        err: (state, err) => state.err = err,
        sb: (state, val) => state.sb = Object.assign(state.sb, val),
        betslip2: (state, val) => state.betslip2 = Object.assign(state.betslip2, val),
        title: (state, val) => state.title = val,
        rememberMe: (state, val) => {
            state.rememberMe = val

            if (val) {
                localStorage.setItem("rememberMe", JSON.stringify(val))
            }
            else {
                localStorage.removeItem("rememberMe")
            }
        },
        stake: (state, val) => state.stake = val
    },
    actions: {
        serverTime () {
            return new Promise((resolve) => {
                axios.get('/ssasia/server-time').then(response => resolve(response.data))
            })
        },
        login ({ commit }, data) {
            console.log(data)
            return new Promise((resolve, reject) => {
                axios.get('/ssasia/server-time').then(response => {
                    let loginId = data.loginId.trim()
                    let password = sha1(sha1(data.password.trim()).toUpperCase() + '#' + response.data.time).toUpperCase()
                    let rememberMe = data.rememberMe

                    axios.post('/oauth/login', { loginId, password })
                        .then((response) => {
                            var data = response.data
                            commit('token', data.accessToken)

                            if (!(data.status === 1005)) {
                                commit("loggedOn", true)

                            }

                            commit("rememberMe", { loginId: rememberMe ? loginId : '', rememberMe })

                            resolve(data)
                        })
                        .catch(err => {
                            console.log(err.response.data)
                            reject(err.response.data)
                        })
                })
            })
        },
        logout ({ commit }) {
            commit('name', null)
            commit("loggedOn", false)
            return new Promise((resolve, reject) => {
                axios.post('/oauth/logout')
                    .then(response => {
                        commit('token', null)
                        resolve(response.data)
                    })
                    .catch(err => {
                        commit('token', null)
                        reject(err.response.data)
                    })
            })
        },
        reset ({ commit }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/user/reset', { 'loginId': data.nickname, "password": sha1(data.newPassword).toUpperCase() })
                    .then((response) => {
                        commit("loggedOn", true)
                        resolve(response.data)
                    })
                    .catch(err => reject(err.response.data))
            })
        },
        changePassword ({ }, data) {
            return new Promise((resolve, reject) => {
                axios.post('/user/password/change', { oldPassword: data.currentPassword, newPassword: data.newPassword })
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        getUser ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/transaction')
                    .then((response) => {
                        commit('user', response.data)
                        resolve(response.data)
                    })
                    .catch((error) => reject(error.response.data))
            })
        },
        getSports ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/transaction/sports2')
                    .then((response) => {
                        var sports = response.data
                        commit('sb', { sports })
                        resolve(sports)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        getOdds ({ }, model) {
            console.log(model)
            return new Promise((resolve, reject) => {
                var url = `/transaction/sports2/${model.sportsbook}/${model.oddsType.toLowerCase()}/${model.sportId}?betTypes=${model.betTypes}`

                if (model.timestamp) {
                    url += `&lastUpdate=${model.timestamp}`
                }

                if (model.sort) {
                    url += `&sortBy=${model.sort}`
                }

                axios.get(url)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        loadSports ({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get('/transaction/sports')
                    .then((response) => {
                        var sports = response.data
                        commit('sports', sports)
                        resolve(sports)
                    })
                    .catch((error) => {
                        reject(error)
                    })
            })
        },
        loadOdds ({ }, model) {
            return new Promise((resolve, reject) => {
                var url = '/transaction/sports'

                if (model.oddsType) {
                    url += `/${model.oddsType.toLowerCase()}`
                }

                url += `/${model.sportsbook}/${model.sportId}/${model.betTypes}`

                if (model.timestamp) {
                    url += `/${model.timestamp}`
                }

                if (model.sort) {
                    url += `?sortBy=${model.sort}`
                }

                axios.get(url)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        loadOddsWithSports ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.get(`/transaction/sports/${model.sportsbook}/${model.sports}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        checkBetting ({ commit }, model) {
            return new Promise((resolve, reject) => {
                commit('betChecking', true)
                axios.get(`/transaction/betting/${model.oddsType.toLowerCase()}/${model.marketId}/${model.handicap}/${model.stakeNumber}/${model.odds}`)
                    .then(response => {
                        var data = response.data
                        resolve(data)
                        commit('betslip', data)
                        commit('active', 'betslip')
                        commit('betChecking', false)
                    })
                    .catch(error => reject(error.response.data))
            })
        },
        bets ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.post('/transaction/betting/bets', model)
                    .then((response) => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        getTicket ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.post(`/transaction/betting/${model.type}`, model.selections)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        betting ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.post('/transaction/betting', model)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        bettingCheck ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.get(`/transaction/betting/${model.id}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        betlist ({ }, model) {
            // Return list of bets with latest 10 bets
            if (model && model['status'] == 'open') {
                return new Promise((resolve, reject) => {
                    axios.get('/transaction/betlist/latest/10')
                        .then(response => resolve(response.data))
                        .catch(error => reject(error.response.data))
                })
            } else {
                var url = '/transaction/betlist'

                if (model) {
                    if ('status' in model)
                        url += `/${model.status}`

                    if ('timestamp' in model)
                        url += `/${model.timestamp}`
                }

                return new Promise((resolve, reject) => {
                    axios.get(url)
                        .then(response => resolve(response.data))
                        .catch(error => reject(error.response.data))
                })
            }
        },
        statement ({ }) {
            return new Promise((resolve, reject) => {
                axios.get('/transaction/statement')
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        getResults ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.get(`/game/result/${model.sportId}?date=${model.date}`)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        getFavorites ({ commit }) {
            return new Promise((resovle, reject) => {
                axios.get('/transaction/favorite')
                    .then(response => {
                        commit('profiles', response.data)
                        resovle(response.data)
                    })
                    .catch(error => reject(error.response.data))
            })
        },
        addFavorites ({ }, model) {
            console.log(model)
            return new Promise((resolve, reject) => {
                axios.post('/transaction/favorite', model)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
        removeFavorites ({ }, model) {
            return new Promise((resolve, reject) => {
                axios.post('/transaction/favorite/remove', model)
                    .then(response => resolve(response.data))
                    .catch(error => reject(error.response.data))
            })
        },
    }
})