//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Odds from './Odds'

export default {
  name: "HalfTimeFullTimeOddEven",
  components: {
    Odds
  },
  props: {
    value: Array
  }
}
