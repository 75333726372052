import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Vuex from 'vuex'
import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import { required, alpha, min, max, between, regex, confirmed } from 'vee-validate/dist/rules'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import ArgonDashboard from './plugins/argon-dashboard'
import './quasar'

Vue.config.productionTip = false
Vue.config.devtools = process.env.NODE_ENV === 'development'

Vue.use(VueI18n)
Vue.use(Vuex)
Vue.use(ArgonDashboard)

// Add validation rules.
extend('required', required)
extend('alpha', alpha)
extend('min', min)
extend('max', max)
extend('regex', regex)
extend('between', between)
extend('confirmed', confirmed)

configure({
  classes: {
    valid: 'has-success',
    invalid: 'has-danger'
  }
})

// // Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

String.prototype.leftpad = function (width, char) {
  if (this.length < width) {
    var paddings = new String();
    for (var i = 1; i <= (width - this.length); i++) {
      paddings += char;
    }
    return (paddings + this);
  }
  else {
    return this;
  }
}

String.prototype.toKey = function () {
  return this.replace(/ /g, "-").toLowerCase()
}

Date.prototype.format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份 
    "d+": this.getDate(), //日 
    "h+": this.getHours(), //小时 
    "m+": this.getMinutes(), //分 
    "s+": this.getSeconds(), //秒 
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度 
    "S": this.getMilliseconds() //毫秒 
  };

  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));

  for (var k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  }

  return fmt;
}

Date.prototype.add = function (date) {
  var d = new Date()
  d.setDate(d.getDate() + date)
  return d;
}

// Filters
Vue.filter('number', function (s) {
  var value = new String(s)
  if (!value) return '0.00'
  var intPart = Number(value) | 0
  var intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
  var floatPart = ".00";
  var value2Array = value.split(".");

  if (value2Array.length == 2) {
    floatPart = value2Array[1].toString();

    if (floatPart.length == 1) {
      return intPartFormat + "." + floatPart + '0';
    }
    else {
      return intPartFormat + "." + Math.round(parseFloat('0.' + floatPart).toFixed(2) * 100);
    }
  }
  else {
    return intPartFormat + floatPart;
  }
})

Vue.filter('fixed', function (num) {
  return num.toFixed(2)
})

Vue.filter('time', function (timestamp) {
  if (timestamp && timestamp !== "") {
    let dt = new Date(timestamp * 1000)
    var hours = dt.getHours()
    var suffix = "AM"

    if (hours > 12) {
      hours -= 12
      suffix = "PM"
    }

    return `${new String(hours).leftpad(2, '0')}:${new String(dt.getMinutes()).leftpad(2, '0')} ${suffix}`
  }

  return timestamp;
})

Vue.filter('date', function (timestamp) {
  let dt = new Date(timestamp * 1000)
  return dt.format("MM/dd")
})

Vue.filter('week', function (timestamp) {
  let weeks = ['week.monday', 'week.tuesday', 'week.wednesday', 'week.thursday', 'week.friday', 'www.saturday', 'week.sunday']
  let dt = new Date(timestamp * 1000)
  return i18n.t(weeks[dt.getDay() - 1])
})

Vue.filter('datetime', function (timestamp) {
  if (!timestamp || timestamp === "") {
    return "-"
  }

  let dt = new Date(timestamp * 1000)

  let year = dt.getFullYear()
  let month = new String(dt.getMonth() + 1).leftpad(2, '0')
  let date = new String(dt.getDate()).leftpad(2, '0')
  let hours = new String(dt.getHours() - (dt.getHours() > 12 ? 12 : 0)).leftpad(2, '0')
  let minutes = new String(dt.getMinutes()).leftpad(2, '0')
  let seconds = new String(dt.getSeconds()).leftpad(2, '0')
  var suffix = dt.getHours() > 12 ? "PM" : "AM"

  return `${year}-${month}-${date} ${hours}:${minutes}:${seconds} ${suffix}`
})

Vue.filter("i18n", function (val, prefix = 'sb') {
  return i18n.t(`${prefix}.${val}`.replace(/ |\//g, "_").toLowerCase())
})

// Directives
Vue.directive('numberOnly', {
  bind: function (el) {
    el.handler = function () {
      el.value = el.value.replace(/\D+/, '')
    }
    el.addEventListener('input', el.handler)
  },
  unbind: function (el) {
    el.removeEventListener('input', el.handler)
  }
})

Vue.directive('focus', {
  inserted: function (el, { value }) {
    if (value) {
      el.focus()

      if (el.select) {
        el.select()
      }
    }
  }
})

const i18n = new VueI18n({
  locale: localStorage.getItem("locale") ? localStorage.getItem("locale") : 'en_US',
  messages: {
    'en_US': require('./i18n/en_US'),
    'id': require('./i18n/id'),
    'my': require('./i18n/my'),
    'th': require('./i18n/th'),
    'vn': require('./i18n/vn'),
    'zh_CHS': require('./i18n/zh_CHS'),
    'zh_CHT': require('./i18n/zh_CHT')
  }
})

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
