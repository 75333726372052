module.exports = {
    sb: {
        refresh: "Refresh",
        print: "Print",
        empty: "No information is available",
        note: {
            title: "Note:",
            content: [
                "The outstanding bets may take some time to process. Please go to Bet List to view your latest outstanding bets.",
                "The time above is displayed in GMT-4."
            ]
        },
        sorts: {
            league: "Sort by League",
            time: "Sort by Time"
        },
        views: {
            single: "Single Line",
            double: "Double Line"
        },
        my_favorites: "My Favorites",
        my_favorites_empty: "My Favorites is empty. Please click the outlined star before matches or leagues to add them here.",
        parlay: "Mix Parlay",
        step: "Step",
        win: "Win",
        win_half: "Win Half",
        loss: "Loss",
        loss_half: "Loss Half",
        draw: "Draw",
        from: "From"
    },
    sportsbook: {
        name: "Wesports",
        early: "Early",
        today: "Today",
        live: "Live",
        parlay: "Mix Parlay",
        step: "Step",
        all: "All"
    },
    login: {
        login_header: "Login",
        login_id: "Login Id",
        login_id_error: "Please input your login Id",
        password: "Password",
        password_error: 'Please input your password',
        password_help: 'Password is case sensitive and must be 6 to 20 characters in length',
        remember_me: "Remember Me?",
        accept: "I accept the license and terms",
        accept_error: "You need to accept the license and terms first",
        login: "Login",
        logout: "Logout",
        reset_header: "Set your account",
        nickname: "Nickname",
        nickname_error: "Please enter nickname.",
        nickname_help: "You can set nickname for display and login (5 to 20 alphabets or digits). Please note that you can set this nickname only once. You cannot modify or cancel it once it’s been set.",
        new_password: "New Password",
        new_password_error: "Please enter new password",
        confirm_password: "Confirm Password",
        confirm_password_error: "Passwords do not match",
        submit: "Submit"
    },
    navs: {
        betlist: "Bet List",
        statement: "Statement",
        result: "Result",
        settings: "Settings"
    },
    user: {
        refresh: "Refresh",
        settings: "Settings",
        show_balance: "Show Balance",
        nickname: "Nickname",
        edit: "Edit",
        cash_balance: "Cash Balance",
        outstanding: "Outstanding",
        net_position: "Net Position",
        bet_credit: "Bet Credit",
        given_credit: "Given Creidt",
        last_login: "Last Login",
        last_transaction: "Last Transaction",
        password_expiry: "Password Expiry"
    },
    betslip: {
        name: "Bet Slip",
        auto_refresh: "Auto Refresh",
        add_to_parlay: "Add to Parlay",
        remove_from_parlay: "Remove from Parlay",
        accept_better_odds: "Accept Better Odds",
        stake: "Stake",
        payout: "Payout",
        min: "Min",
        max: "Max",
        process_bet: "Process Bet",
        cancel: "Cancel",
        confirm_bet: "Confirm Bet?",
        yes: "Yes",
        no: "No",
        min_stake_alert: "Your stake is less than the min stake.",
        no_bet_alert: "Your bet slip is empty, please click on odds in the table to make your bet selection.",
        single: "Single",
        parlay: "Parlay",
        step: "Step",
        parlay_add: "Add to Parlay",
        parlay_remove: "Remove from Parlay",
        // Parlay
        accept_any_odds: "Auto Accept Any Odds",
        expand_all: "Expand All",
        total_bets: "Total Bets",
        total_stake: "Total Stake",
        max_payout: "Max Payout",
        step_add: "Add to Step",
        step_remove: "Remove from Step",
        close: "Close"
    },
    ticket: {
        id: "ID",
        stake: "Stake",
        payout: "Payout",
        stauts: "Status",
        timestamp: "Transaction Time"
    },
    sports: {
        name: "အၾကိဳက္ဆုံးမ်ား",
        soccer: " ေဘာလံုးၿပိဳင္ပြဲ",
        basketball: "Basketball",
        early: "Awal",
        today: "Hari Ini",
        live: "Langsung",
        no_matches: "No matches available",
        hdpou: "HDP & OU",
        match_odds_1x2: "Match Odds 1x2",
        correct_score: "Correct Score",
        odd_even: "Odd/Even",
        total_goal: "Total Goal",
        halftime_fulltime: "Half Time/Full Time",
        halftime_fulltime_odd_even: "HT / FT Odd/Even",
        first_goal_lat_goal: "First Goal/Last Goal",
        mix_parlay: "Mix Parlay",
        step: "Step",
        outright: "Outright"
    },
    betgroups: {
        "1": "HDP & OU",
        "2": "Match Odds 1x2",
        "3": "Correct Score",
        "4": "Odd/Even",
        "5": "Total Goal",
        "6": "Half Time/Full Time",
        "7": "HT / FT Odd/Even",
        "8": "First Goal/Last Goal",
        "9": "Mix Parlay",
        "10": "Step",
        "11": "Outright"
    },
    bettypes: {
        'full-time-handicap': "Full Time Handicap",
        'full-time-handicap-header': "FT.HDP",
        'full-time-over/under': "Full Time Over/Under",
        'full-time-over/under-header': "FT.O/U",
        'full-time-1x2': "Full Time 1X2",
        'full-time-1x2-header': "FT.1X2",
        'half-time-handicap': "Half Time Handicap",
        'half-time-handicap-header': "1H.HDP",
        'half-time-over/under': "Half Time Over/Under",
        'half-time-over/under-header': "1H.O/U",
        'half-time-1x2': "Half Time 1X2",
        'half-time-1x2-header': "1H.1X2"
    },
    odds_types: {
        DEC: "Decimal Odds",
        MY: "Malay Odds",
        HK: "Hong Kong Odds",
        INDO: "Indonesia Odds"
    },
    odds: {
        event: "Event",
        time: "Time",
        full_time: "Full Time",
        half_time: "Half Time",
        title: "Odds",
        draw: "Draw",
        under: "u",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "Odd",
            even: "Even"
        },
        fglg: {
            first_goal: "First Goal",
            last_goal: "Last Goal",
            no_goal: "No Goal",
        },
        hf: {
            home_home: "HH",
            home_draw: "HD",
            home_away: "HA",
            draw_home: "DH",
            draw_draw: "DD",
            draw_away: "DA",
            away_home: "AH",
            away_draw: "AD",
            away_away: "AA"
        },
        hfoe: {
            odd_odd: "Odd/Odd",
            odd_even: "Odd/Even",
            even_odd: "Even/Odd",
            even_even: "Even/Even",
        },
        single_line: {
            full_time_handicap: "Handicap",
            half_time_handicap: "1H Handicap",
            handicap: 'HDP',
            h: "H",
            home: "Home",
            a: "A",
            away: "Aawy",
            goal: "Goal",
            over: "Over",
            under: "Under"
        }
    },
    alerts: {
        no_matches: "No matches or games available now, please visit other sport events or games or come back later."
    },
    league_filter: {
        leagues: "Leagues",
        title: "Select Leagues",
        check_all: "Check All",
        close: "Close",
        submit: "Go",
        cancel: "Cancel"
    },
    month: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec"
    },
    week: {
        mon: "Mon",
        tues: "Tue",
        web: "Wed",
        thur: "Thu",
        fri: "Fri",
        sat: "Sat",
        sun: "Sun",
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    },
    betlist: {
        name: "Bet List",
        bets: "Bets",
        waiting: "Waiting",
        void: "Void",
        open: "Running",
        pending: "Waiting",
        canceled: "Canceled",
        no_bets: "No Running Bet",
        no_waiting: "No Waiting Bet",
        no_void: "No Void Bet",
        header: {
            no: "No.",
            date: "Date",
            choice: "Choice",
            odds: "Odds",
            stake: "Stake",
            status: "Status"
        },
        from: "From",
        status: {
            void: "Void",
            open: "Running",
            pending: "Waiting",
            canceled: "Canceled"
        }
    },
    statement: {
        name: "Statement",
        date: "Date",
        remark: "Remark",
        turnover: "Turnover",
        credit_debit: "Credit / Debit",
        commission: "Commission",
        balance: "Balance",
        opening_balance: "Opening Balance",
        betting_statement: "Betting Statement",
        outstanding_bet: "Outstanding Bet",
        bet_list: "Bet List",
        bet_list_no: "No.",
        bet_list_transaction_time: "Trans.Time",
        bet_list_event: "Event",
        bet_list_odds: "Odds",
        bet_list_stake: "Stake",
        bet_list_win_loss: "Win / Loss",
        bet_list_status: "Status",
        bet_list_status_running: "Running",
        bet_list_subtotal: "Subtotal",
        bet_list_subtotal_commission: "Subtotal(Commission)",
        bet_list_total: "Total",
        bet_summary: "Bet Summary",
        bet_summary_date: "Date",
        bet_summary_bet_types: "Bet Types",
        bet_summary_event: "Event",
        bet_summary_stake: "Stake",
        bet_summary_credit_debit: "Credit / Debit"
    },
    result: {
        title: "Result",
        result: "Result",
        today: "Today",
        yesterday: "Yesterday",
        all_leagues: "All Leagues",
        kickoff_time: "Kickoff Time",
        match: "Match",
        first_half: "First Half",
        final: "Fianl",
        status: "Status",
        outright: {
            text: "Outright",
            time: "Time",
            team: "Team",
            result: "Result"
        }
    },
    settings: {
        change_account_password: "Change Account Password",
        current_password: "Current Password",
        current_password_error: "Please enter current password",
        new_password: "New Password",
        new_password_error: "Please enter new password",
        confirm_password: "Confirm Password",
        confirm_password_error: "Passwords do not match",
        password_help: "Password is case sensitive and must be 6 to 20 characters in length.",
        change_account_nickname: "Change Account Nickname",
        nickname: "Nickname",
        nickname_error: "Please enter nickname",
        nickname_help: "You can set nickname for display and login (5 to 20 alphabets or digits). Please note that you can set this nickname only once. You cannot modify or cancel it once it’s been set.",
        submit: "Submit"
    },
    combos: {
        single: "Single",
        double: "Double",
        treble: "Treble",
        fold4: "4-Fold",
        fold5: "5-Fold",
        fold6: "6-Fold",
        fold7: "7-Fold",
        fold8: "8-Fold",
        fold9: "9-Fold",
        fold10: "10-Fold",
        fold11: "11-Fold",
        fold12: "12-Fold",
        fold13: "13-Fold",
        fold14: "14-Fold",
        fold15: "15-Fold",
        fold16: "16-Fold",
        fold17: "17-Fold",
        fold18: "18-Fold",
        fold19: "19-Fold",
        fold20: "20-Fold"
    }
}