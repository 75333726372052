export default {
    get: function(key, defaultValue = '') {
        let value = localStorage.getItem(key)
        
        return value ? value : defaultValue;
    },
    set: function(key, value) {
        localStorage.setItem(key, value)
    },
    remove: function(key) {
        localStorage.removeItem(key)
    }
}