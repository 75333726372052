//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "betslip",
  data () {
    return {
      tabs: [
        {
          name: "single",
          icon: "crop_din",
          stake: 0,
          confirm: false,
          ticket: { type: 1, acceptOdds: 2, selections: [], bets: [], total: 0, stake: 0, payout: 0, minBet: 0, maxBet: 0 },
          timer: 0
        },
        { name: "parlay", icon: "filter_none", stake: 0, confirm: false, ticket: { type: 2, acceptOdds: 3, selections: [], bets: [], total: 0, stake: 0, payout: 0, minBet: 0, maxBet: 0 }, timer: 0 },
        { name: "step", icon: "filter_none", stake: 0, confirm: false, ticket: { type: 3, acceptOdds: 3, selections: [], bets: [], total: 0, stake: 0, payout: 0, minBet: 0, maxBet: 0 }, timer: 0 }
      ],
      current: null,
      submitting: false,
      checkedParlay: false,
      checkedStep: false,
      loading: false,
      refresh: {
        auto: true,
        countDown: 9,
        timer: null
      }
    };
  },
  props: {},
  mounted () {
    this.current = this.tabs[0]

    this.$watch(
      "$store.state.betslip",
      newVal => {
        if (newVal) {
          let tab = newVal.type - 1
          // Single
          if (newVal.type === 1) {
            this.close(this.tabs[tab])
            this.tabs[tab] = Object.assign(this.tabs[tab], { stake: 0, confirm: false, ticket: newVal });
            this.checkedParlay = this.checked(this.tabs[1].ticket, newVal.selections[0])
            this.checkedStep = this.checked(this.tabs[2].ticket, newVal.selections[0])

            this.$nextTick(() => this.$refs.singleStakeInput[0].focus())
          }
          else {
            this.putSelection(this.tabs[tab], newVal.selections[0])
          }

          this.current = this.tabs[tab];
        }
      },
      { immediate: true }
    )

    this.$watch("checkedParlay", newVal => {
      if (this.current.ticket) {
        let selection = this.current.ticket.selections[0]
        if (newVal) {
          this.putSelection(this.tabs[1], selection);
        }
        else {
          this.removeSelection(this.tabs[1], selection);
        }
      }
    }, { immediate: true });

    this.$watch("checkedStep", newVal => {
      if (this.current.ticket) {
        let selection = this.current.ticket.selections[0]
        if (newVal) {
          this.putSelection(this.tabs[2], selection);
        }
        else {
          this.removeSelection(this.tabs[2], selection);
        }
      }
    }, { immediate: true })

    this.$watch('$i18n.locale', () => {
      if (this.current) {
        this.submitting = true;
        let ticket = this.current.ticket
        this.$store.dispatch('getTicket', ticket).then(data => {
          let stake = this.current.stake
          this.current.ticket = Object.assign(data, { payout: stake * data.payout, stake: stake })
          this.submitting = false;
        })
      }
    }, { immediate: true })

    this.$watch('refresh.auto', (auto) => {
      if (auto) {
        this.startRefresh()
      } else {
        this.stopRefresh()
      }
    }, { immediate: true })
  },
  destroyed () {
    if (this.current.clock) {
      window.clearInterval(this.current.clock)
    }

    if (this.refresh.timer) {
      clearInterval(this.refresh.timer)
      this.refresh = Object.assign(this.refresh, { timer: null, countDown: 9 })
    }

    console.log("Betslip destroyed.")
  },
  computed: {
    inParlay: function () {
      return this.current.name !== "single";
    },
    inChecking: function () {
      return this.$store.state.betChecking;
    },
    currency: function () {
      return this.$store.state.user ? this.$store.state.user.currency : "";
    },
    betControls: function () {
      var value = 'stake-pre-bet'
      for (var bet in this.current.ticket.bets) {
        value += ` bet-${bet.combo}`
      }

      return value
    }
  },
  watch: {
    'current.ticket.selections': function (newVal) {
      if (newVal.length > 2) {
        this.loading = true
        this.current.confirm = false
        if (this.current.ticket.type === 2) {
          this.$store.dispatch('getTicket', { type: 2, selections: newVal })
            .then(ticket => {
              this.current.ticket = Object.assign(this.current.ticket, {
                bets: ticket.bets,
                minBet: ticket.minBet,
                maxBet: ticket.maxBet,
                total: ticket.total,
                stake: ticket.state,
                payout: ticket.payout
              })
              this.loading = false

              this.$nextTick(() => this.$refs.parlayStakeInput[0].focus())
            })
            .catch(err => {
              this.current.ticket = Object.assign(this.current.ticket, { message: err.data })
              this.loading = false
            })
        } else {
          this.$store.dispatch('bets', newVal)
            .then(bets => {
              let minBet = Math.min(...bets.map(bet => bet.minBet))
              let maxBet = Math.max(...bets.map(bet => bet.maxBet))
              let total = 0, stake = 0, payout = 0
              this.current.ticket = Object.assign(this.current.ticket, { bets, minBet, maxBet, total, stake, payout })
              this.loading = false

              this.$nextTick(() => this.$refs.stepStakeInput[0].focus())
            })
            .catch(err => {
              this.current.ticket = Object.assign(this.current.ticket, { message: err.data })
              this.loading = false
            })
        }
      }
      else {
        this.current.ticket = Object.assign(this.current.ticket, { bets: [] })
      }

      if (newVal.length > 0) {
        this.startRefresh()
      } else {
        this.stopRefresh()
      }
    },
    'current.stake': function (newVal) {
      var ticket = this.current.ticket
      if (ticket.selections.length > 0) {
        if (this.isSingle(this.current)) {
          const stake = newVal
          const odds = ticket.selections[0].odds
          const payout = ticket.selections[0].oddsType === 'DEC' ? stake * odds : stake * (Math.abs(odds) + 1)

          ticket = Object.assign(ticket, { stake, payout })
        }
        else {
          var entry = { total: 0, stake: 0, payout: 0 }
          ticket.bets.forEach(bet => {
            bet.stake = Math.min(newVal, bet.maxBet)
            if (bet.stake) {
              entry.total += bet.count
              entry.stake += bet.stake * bet.count
              entry.payout += parseFloat((bet.stake * bet.odds).toFixed(2))
            }
          })
          this.current.ticket = Object.assign(this.current.ticket, entry)
        }
      }
    },
    'current.timer': function (newVal) {
      if (!this.current.clock && newVal > 0) {
        let clock = window.setInterval((tab) => {
          tab.timer--
          if (tab.timer <= 0) {
            this.gotoBetlist(tab)
          }
        }, 1000, Object.assign(this.current, { clock: clock }));
        this.current = Object.assign(this.current, { clock: clock })
      }
    }
  },
  methods: {
    checked: function (ticket, selection) {
      return ticket && ticket.selections
        .findIndex(s => s.marketId === selection.marketId && s.stakeNumber === selection.stakeNumber) > -1
    },
    isSingle: function (tab) {
      return tab.name === 'single'
    },
    loadPayout: function () {
      var entry = { total: 0, stake: 0, payout: 0 }
      this.current.ticket.bets
        .filter(bet => bet.stake > 0)
        .forEach(bet => {
          entry.total += bet.count
          entry.stake += bet.stake * bet.count
          entry.payout += bet.stake * bet.odds
        })
      this.current.ticket = Object.assign(this.current.ticket, entry)
    },
    putSelection: function (tab, selection) {
      if (tab.ticket) {
        let selections = tab.ticket.selections
        let i = selections.findIndex(s => s.eventId === selection.eventId);

        if (i == -1) {
          tab.ticket.selections.push(selection);
        }
        else {
          tab.ticket.selections = selections
            .slice(0, i)
            .concat([selection])
            .concat(selections.slice(i + 1, selections.length));
        }
      }
      else {
        tab.ticket = { selections: [selection] }
      }
    },
    removeSelection: function (tab, selection) {
      if (tab.ticket) {
        let selections = tab.ticket.selections;
        let i = selections.findIndex(s => s.marketId === selection.marketId && s.stakeNumber === selection.stakeNumber);

        if (i > -1) {
          tab.ticket.selections = selections
            .slice(0, i)
            .concat(selections.slice(i + 1, selections.length));
        }
      }
    },
    getStake: function (stake, min, max) {
      return Math.min(stake.replace(/\D+/, ""), max);
    },
    inputStake: function (bet, stake) {
      bet.stake = this.getStake(stake, bet.minBet, bet.maxBet)
      this.loadPayout()
    },
    cleanupStake: function () {
      this.current.stake = 0
      this.current.ticket = Object.assign(this.current.ticket, { message: "" })
    },
    close: function (current) {
      if (!current) {
        current = this.current
      }

      if (current.clock) {
        window.clearInterval(current.clock);
      }
      current = Object.assign(current, { confirm: false, timer: 0, clock: null })
      current.ticket = Object.assign(current.ticket, { id: null, status: 0, selections: [], bets: [], total: 0, stake: 0, payout: 0, minBet: 0, maxBet: 0 });
    },
    gotoBetlist: function (current) {
      if (!current) {
        current = this.current
      }

      this.$store.commit('betlist', 0)
      this.$store.commit('active', 'betlist')
      this.close(current)

      // var id = current.ticket.id
      // var status = current.ticket.status

      // if (status === 'OPEN') {
      //   this.$store.commit('betlist', 0)
      //   this.$store.commit('active', 'betlist')
      //   this.close(current)
      // } else {
      //   console.log('bettingCheck')
      //   this.$store.dispatch('bettingCheck', { id })
      //     .then(result => {
      //       this.$store.commit('betlist', result.status === 'PENDING' ? 1 : (result.status === 'CANCELED' ? 2 : 0))
      //       this.$store.commit('active', 'betlist')
      //       this.close(current)
      //     })
      //     .catch(() => {
      //       this.$store.commit('betlist', 0)
      //       this.$store.commit('active', 'betlist')
      //       this.close(current)
      //     })
      // }
    },
    processBet: function () {
      let ticket = this.current.ticket;
      if (ticket.type === 1) {
        // Single
        if (ticket.stake < ticket.minBet) {
          ticket.message = this.$t("betslip.min_stake_alert");
          this.current.stake = ticket.minBet;
          return;
        }
        ticket.message = "";
      } else {
        if (ticket.bets.filter(bet => bet.stake >= ticket.minBet).length == 0) {
          ticket.message = this.$t("betslip.min_stake_alert");
          this.current.stake = ticket.minBet;
          return;
        }
        ticket.message = "";
      }

      this.stopRefresh()
      this.current.confirm = true;
    },
    cancelBet: function () {
      this.stopRefresh()
      this.close()
      this.$store.commit('active', 'all-sports')
    },
    confirmYes: function () {
      let ticket = this.current.ticket;
      this.submitting = true;
      this.$store.dispatch("betting", ticket)
        .then(result => {
          this.submitting = false;
          this.current = Object.assign(this.current, { timer: 5 })
          this.current.ticket = Object.assign(this.current.ticket, {
            id: result.id,
            type: result.type,
            stake: result.stake,
            payout: result.payout,
            status: result.status,
            stakeBonus: result.stakeBonus,
            timestamp: result.timestamp,
            message: result.message,
            selections: [],
            bets: []
          })
          // reload user
          this.$store.dispatch("getUser")
        })
        .catch(err => {
          ticket.message = err.message;
          this.submitting = false;
          this.current.confirm = false;
          this.startRefresh()
          if (err.oddsChange) {
            this.current.stake = err.stake
            this.current.ticket = Object.assign(this.current.ticket, { stake: err.stake, payout: err.payout, selections: [err.selection] })
          }
        })
    },
    confirmNo: function () {
      this.current.confirm = false
      this.startRefresh()
    },
    startRefresh: function () {
      if (this.refresh.auto) {
        this.refresh.countDown = 9
        if (!this.refresh.timer) {
          this.refresh.timer = setInterval(() => {
            if (this.refresh.countDown > 0) {
              this.refresh.countDown--

              if (this.refresh.countDown === 0) {
                this.submitting = true;
                let ticket = this.current.ticket
                this.$store.dispatch('getTicket', ticket).then(data => {
                  let stake = this.current.stake
                  this.current.ticket = Object.assign(data, { payout: stake * data.payout, stake: stake })
                  this.submitting = false
                  this.refresh.countDown = 9

                  if (data.selections.length === 0) {
                    this.$q.notify({
                      color: 'red-5',
                      textColor: 'white',
                      icon: 'info',
                      position: 'top',
                      message: "The market closed/suppend."
                    })
                  }
                })
              }
            }
          }, 1000)
        }
      }
    },
    stopRefresh: function () {
      if (this.refresh.timer) {
        clearInterval(this.refresh.timer)
        this.refresh = Object.assign(this.refresh, { timer: null, countDown: 9 })
      }
    }
  }
};
