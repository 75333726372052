import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import DefaultLayout from './m/layouts/Default.vue'
import SubpageLayout from './m/layouts/Subpage'
import Home from './m/views/Home.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: 'sports',
      component: DashboardLayout,
      children: [
        {
          path: '/sports',
          name: 'Sports',
          component: () => import('./views/Sports.vue')
        },
        {
          path: '/hdpou/:sportsbook',
          component: () => import('./views/Hdpou.vue')
        },
        {
          path: '/hdpou/:sportsbook/:sports',
          name: 'Live',
          component: () => import('./views/Hdpou.vue')
        },
        {
          path: '/:sportsbook/:sportId/hdpou',
          name: 'Hdpou',
          component: () => import('./views/Hdpou.vue')
        },
        {
          path: '/:sportsbook/:sportId/1x2',
          name: '1x2',
          component: () => import('./views/1x2.vue')
        },
        {
          path: '/:sportsbook/:sportId/cs',
          name: 'CorrectScore',
          component: () => import('./views/CorrectScore.vue')
        },
        {
          path: '/:sportsbook/:sportId/oe',
          name: 'OddEven',
          component: () => import('./views/OddEven.vue')
        },
        {
          path: '/:sportsbook/:sportId/tg',
          name: 'TotalGoal',
          component: () => import('./views/TotalGoal.vue')
        },
        {
          path: '/:sportsbook/:sportId/htft',
          name: 'HalfTimeFullTime',
          component: () => import('./views/HalfTimeFullTime.vue')
        },
        {
          path: '/:sportsbook/:sportId/hfoe',
          name: 'HalfTimeFullTimeOddEven',
          component: () => import('./views/HalfTimeFullTimeOddEven.vue')
        },
        {
          path: '/:sportsbook/:sportId/fglg',
          name: 'FirstGoalLastGoal',
          component: () => import('./views/FirstGoalLastGoal.vue')
        },
        {
          path: '/:sportsbook/:sportId/parlay',
          name: 'Parlay',
          component: () => import('./views/Parlay.vue')
        },
        {
          path: '/:sportsbook/:sportId/step',
          name: 'Step',
          component: () => import('./views/Step.vue')
        },
        {
          path: '/favorites',
          name: 'MyFavorites',
          component: () => import('./views/Favorites.vue')
        },
        {
          path: '/betlist',
          name: 'BetList',
          component: () => import('./views/BetList.vue')
        },
        {
          path: '/statement',
          name: 'Statement',
          component: () => import('./views/Statement.vue')
        },
        {
          path: '/statement/:timestamp/:type',
          name: 'StatementDetails',
          component: () => import('./views/StatementDetails.vue')
        },
        {
          path: '/result',
          name: 'Result',
          component: () => import('./views/Result.vue')
        },
        {
          path: '/settings',
          name: 'Settings',
          component: () => import('./views/Settings.vue')
        },
        {
          path: '/icons',
          name: 'Icons',
          component: () => import('./views/Icons.vue')
        }
      ]
    },
    {
      path: '/m',
      component: DefaultLayout,
      children: [
        {
          path: '',
          name: 'home',
          component: Home
        }
      ]
    },
    {
      path: '/m/:sportsbook/:sportId/:layout',
      component: DefaultLayout,
      children: [
        {
          path: '/m/:sportsbook/:sportId/:layout',
          name: 'home',
          component: Home
        }
      ]
    },
    {
      path: '/m/login',
      component: () => import('./m/views/Login.vue')
    },
    {
      path: '/m/*',
      component: SubpageLayout,
      children: [
        {
          path: '/m/account',
          name: 'Account',
          component: () => import('./m/views/Account.vue')
        },
        {
          path: '/m/favorites',
          name: 'Favorites',
          component: () => import('./m/views/Favorites.vue')
        },
        {
          path: '/m/betlist',
          name: 'Betlist',
          component: () => import('./m/views/Betlist.vue')
        },
        {
          path: '/m/statement',
          name: 'Statement',
          component: () => import('./m/views/Statement.vue')
        },
        {
          path: '/m/statement/details/:timestamp/:type',
          name: 'StatementDetails',
          component: () => import('./m/views/StatementDetails.vue')
        },
        {
          path: '/m/result',
          name: 'Result',
          component: () => import('./m/views/Result.vue')
        },
        {
          path: '/m/settings',
          name: 'Settings',
          component: () => import('./m/views/Settings.vue')
        },
        {
          path: '/m/settings/language',
          name: 'Language',
          component: () => import('./m/views/Language.vue')
        },
        {
          path: '/m/settings/odds-type',
          name: 'OddsType',
          component: () => import('./m/views/OddsType.vue')
        },
        {
          path: '/m/settings/change-password',
          name: 'ChangePassword',
          component: () => import('./m/views/ChangePassword.vue')
        }
      ]
    }
  ]
})
