import { render, staticRenderFns } from "./Default.vue?vue&type=template&id=60b5d13b&scoped=true&"
import script from "./Default.vue?vue&type=script&lang=js&"
export * from "./Default.vue?vue&type=script&lang=js&"
import style0 from "./Default.vue?vue&type=style&index=0&id=60b5d13b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b5d13b",
  null
  
)

export default component.exports
import {QTabPanels,QTabPanel,QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QList,QItem,QItemSection,QAvatar,QItemLabel,QSeparator,QIcon,QPageContainer,QColor,Ripple} from 'quasar'
component.options.components = Object.assign({QTabPanels,QTabPanel,QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QDrawer,QList,QItem,QItemSection,QAvatar,QItemLabel,QSeparator,QIcon,QPageContainer,QColor}, component.options.components || {})
component.options.directives = Object.assign({Ripple}, component.options.directives || {})
