//
//
//
//
//
//
//
//
//

export default {
  name: 'base-header',
  props: {
    type: {
      type: String,
      default: 'primary',
      description: 'Header background type'
    }
  }
}
