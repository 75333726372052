module.exports = {
    sb: {
        refresh: "刷新",
        print: "打印",
        empty: "没有记录",
        note: {
            title: "注意：",
            content: [
                "最新未完成投注可能需要一些时间处理，可至投注历史查询您的投注纪录。",
                "显示时间为GMT-4时区。"
            ]
        },
        sorts: {
            league: "按联赛排序",
            time: "按时间排序"
        },
        views: {
            single: "单线单盘口",
            double: "双线双盘口"
        },
        my_favorites: "我的最爱",
        my_favorites_empty: "您尚未在我的最爱中加入任何赛事。请点选联赛前方或赛事中的星号将其设为我的最爱。",
        parlay: "混合串关",
        step: "泰式串关",
        win: "赢",
        win_half: "赢一半",
        loss: "输",
        loss_half: "输一半",
        draw: "和局",
        from: "来自"
    },
    sportsbook: {
        name: "Wesports",
        early: "早盘",
        today: "今日赛事",
        live: "滚球",
        parlay: "混合串关",
        step: "泰式串关",
        all: "全部"
    },
    login: {
        login_header: "登录",
        login_id: "户名",
        login_id_error: "请输入户名",
        password: "密码",
        password_error: "请输入密码",
        password_help: "密码区分大小写，长度必须为6至10个字符。",
        remember_me: "记住我?",
        accept: "同意服务协议和用户隐私政策条款",
        accept_error: "你必须先同意服务协议和用户隐私政策条款",
        login: "登录",
        logout: "退出",
        reset_header: "设置帐户",
        nickname: "昵称",
        nickname_error: "请输入新密码",
        nickname_help: "您可以设置显示和登录昵称（5至15位数字或字母）。请注意，您仅能设置一次昵称。一旦设置成功，您将无法修改或取消。",
        new_password: "新密码",
        new_password_error: "请输入新密码",
        confirm_password: "再次输入您的新密码",
        confirm_password_error: "密码不匹配",
        submit: "提交"
    },
    navs: {
        betlist: "投注历史",
        statement: "帐目",
        result: "赛果",
        settings: "设置"
    },
    user: {
        refresh: "刷新",
        settings: "设置",
        show_balance: "显示余额",
        nickname: "昵称",
        edit: "编辑",
        cash_balance: "现金余额",
        outstanding: "未结束投注",
        net_position: "Net Position",
        bet_credit: "投注额度",
        given_credit: "信用额度",
        last_login: "最后登录",
        last_transaction: "最后交易",
        password_expiry: "密码过期"
    },
    betslip: {
        name: "投注单",
        auto_refresh: "自动刷新",
        add_to_parlay: "加入混合串关",
        remove_from_parlay: "从混合串关移除",
        accept_better_odds: "自动接受较佳赔率",
        stake: "投注额",
        payout: "贏取金額",
        min: "最低投注",
        max: "最高投注",
        process_bet: "下注",
        cancel: "取消",
        confirm_bet: "您的投注金额少于最低投注额。",
        yes: "是",
        no: "否",
        min_stake_alert: "您的投注金额少于最低投注额。",
        no_bet_alert: "您的投注单是空的，请点选画面上的赔率进行下注。",
        single: "单笔投注",
        parlay: "混合串关",
        step: "泰式串关",
        parlay_add: "加入混合串关",
        parlay_remove: "从混合串关移除",
        // Parlay
        accept_any_odds: "自动接受任何赔率",
        expand_all: "单笔投注",
        total_bets: "总注单数",
        total_stake: "总投注额",
        max_payout: "最大赢取金额",
        step_add: "加入泰式串关",
        step_remove: "从泰式串关移除",
        close: "Close"
    },
    ticket: {
        id: "ID",
        stake: "投注金額",
        payout: "赢取金额",
        stauts: "状态",
        timestamp: "交易时间"
    },
    sports: {
        name: "所有运动",
        soccer: "足球",
        basketball: "篮球",
        early: "早盘",
        today: "今日赛事",
        live: "滚球",
        no_matches: "没有可投注的赛事或游戏",
        hdpou: "让球 & 大小",
        match_odds_1x2: "全场 & 半场 独赢",
        correct_score: "全场 & 半场 波胆",
        odd_even: "全场 & 半场 单/双",
        total_goal: "全场 & 半场 总进球",
        halftime_fulltime: "半场/全场",
        halftime_fulltime_odd_even: "半场/全场  单/双",
        first_goal_lat_goal: "全场 & 半场 最先进球 / 最后进球",
        mix_parlay: "混合串关",
        step: "泰式串关",
        outright: "优胜冠军"
    },
    betgroups: {
        "1": "让球 & 大小",
        "2": "全场 & 半场 独赢",
        "3": "全场 & 半场 波胆",
        "4": "全场 & 半场 单/双",
        "5": "全场 & 半场 总进球",
        "6": "半场/全场",
        "7": "半场/全场  单/双",
        "8": "全场 & 半场 最先进球 / 最后进球",
        "9": "混合串关",
        "10": "泰式串关",
        "11": "优胜冠军"
    },
    bettypes: {
        'full-time-handicap': "让球",
        'full-time-handicap-header': "全场让球",
        'full-time-over/under': "大小盘",
        'full-time-over/under-header': "全场大/小",
        'full-time-1x2': "全场.独赢盘",
        'full-time-1x2-header': "全场独赢",
        'half-time-handicap': "上半场让球",
        'half-time-handicap-header': "上半场让球",
        'half-time-over/under': "上半场大小盘",
        'half-time-over/under-header': "上半场大/小",
        'half-time-1x2': "上半场独赢",
        'half-time-1x2-header': "上半场独赢"
    },
    odds_types: {
        DEC: "欧洲盘",
        MY: "马来盘",
        HK: "香港盘",
        INDO: "印度盘"
    },
    odds: {
        event: "赛事",
        time: "时间",
        full_time: "全场",
        half_time: "半场",
        title: "赔率",
        draw: "和局",
        under: "小",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "单",
            even: "双"
        },
        fglg: {
            first_goal: "最先进球",
            last_goal: "最后进球",
            no_goal: "无进球",
        },
        hf: {
            home_home: "主主",
            home_draw: "主和",
            home_away: "主客",
            draw_home: "和主",
            draw_draw: "和和",
            draw_away: "和客",
            away_home: "客主",
            away_draw: "客和",
            away_away: "客客"
        },
        hfoe: {
            odd_odd: "单/单",
            odd_even: "单/双",
            even_odd: "双/单",
            even_even: "双/双"
        },
        single_line: {
            full_time_handicap: "全场 让球",
            half_time_handicap: "上半场 让球",
            handicap: '让球',
            h: "主",
            home: "主",
            a: "客",
            away: "客",
            goal: "总进球",
            over: "大",
            under: "小"
        }
    },
    alerts: {
        no_matches: "当前没有可投注的赛事或游戏，您可先至其他运动或游戏进行下注，或稍后回来查看。"
    },
    league_filter: {
        leagues: "联赛",
        title: "选择联赛",
        check_all: "全选",
        close: "关闭",
        submit: "提交",
        cancel: "取消"
    },
    month: {
        jan: "一月",
        feb: "二月",
        mar: "三月",
        apr: "四月",
        may: "五月",
        jun: "六月",
        jul: "七月",
        aug: "八月",
        sep: "九月",
        oct: "十月",
        nov: "十一月",
        dec: "十二月"
    },
    week: {
        mon: "一",
        tues: "二",
        web: "三",
        thur: "四",
        fri: "五",
        sat: "六",
        sun: "日",
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日'
    },
    betlist: {
        name: "投注历史",
        bets: "进行中",
        waiting: "等待中",
        void: "作废",
        open: "进行中",
        pending: "等待中",
        canceled: "作废",
        no_bets: "没有任何进行中的投注",
        no_waiting: "没有任何等待中的投注",
        no_void: "没有任何作废的投注",
        header: {
            no: "编号",
            date: "日期",
            choice: "选择",
            odds: "赔率",
            stake: "投注金额",
            status: "状态"
        },
        from: "来自",
        status: {
            void: "已作废",
            open: "进行中",
            pending: "待确定",
            canceled: "已取消"
        }
    },
    statement: {
        name: "账目",
        date: "日期",
        remark: "备注",
        turnover: "交易额",
        credit_debit: "支出 / 收入",
        commission: "佣金",
        balance: "余额",
        opening_balance: "前期余额",
        betting_statement: "投注帳目",
        outstanding_bet: "未完成交易",
        bet_list: "投注历史",
        bet_list_no: "编号",
        bet_list_transaction_time: "交易时间",
        bet_list_event: "赛事",
        bet_list_odds: "赔率",
        bet_list_stake: "投注金额",
        bet_list_win_loss: "输赢",
        bet_list_status: "状态",
        bet_list_subtotal: "小计(赢)",
        bet_list_subtotal_commission: "小计(佣金)",
        bet_list_total: "总数",
        bet_summary: "投注摘要",
        bet_summary_date: "日期",
        bet_summary_bet_types: "投注类型",
        bet_summary_event: "赛事",
        bet_summary_stake: "投注金额",
        bet_summary_credit_debit: "支出 / 收入"
    },
    result: {
        title: "赛果",
        result: "赛果",
        today: "今日",
        yesterday: "昨日赛事",
        all_leagues: "所有联赛",
        kickoff_time: "开赛时间",
        match: "赛事",
        first_half: "上半场比分",
        final: "最后赛果",
        status: "状态",
        outright: {
            text: "优胜冠军",
            time: "时间",
            team: "队名",
            result: "赛果"
        }
    },
    settings: {
        change_account_password: "更改帐户密码",
        current_password: "当前密码",
        current_password_error: "请输入当前密码",
        new_password: "新密码",
        new_password_error: "请输入新密码",
        confirm_password: "再次输入您的新密码",
        confirm_password_error: "密码不匹配",
        password_help: "密码区分大小写，长度必须为6至20个字符。",
        change_account_nickname: "更改帐户昵称",
        nickname: "昵称",
        nickname_error: "请输入昵称",
        nickname_help: "您可以设置显示和登录昵称（5至20位数字或字母）。请注意，您仅能设置一次昵称。一旦设置成功，您将无法修改或取消。",
        submit: "提交"
    }
}