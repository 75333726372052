//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FadeTransition } from "vue2-transitions";

export default {
  name: "base-alert",
  components: {
    FadeTransition
  },
  props: {
    type: {
      type: String,
      default: "default",
      description: "Alert type"
    },
    icon: {
      type: String,
      default: "",
      description: "Alert icon. Will be overwritten by default slot"
    },
    dismissible: {
      type: Boolean,
      default: false,
      description: "Whether alert is closes when clicking"
    }
  },
  data() {
    return {
      visible: true
    };
  },
  methods: {
    dismissAlert() {
      this.visible = false;
    }
  }
};
