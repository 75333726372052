//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FadeTransition } from "vue2-transitions"
import Clock from '../components/Clock'
import Login from '../components/Login'
import UserInfo from '../components/UserInfo'
import Betslip from '../components/Betslip'
import Betlist from '../components/Betlist'
import SportCard from '../components/SportCard'

export default {
  components: {
    FadeTransition,
    Clock,
    Login,
    UserInfo,
    Betslip,
    Betlist,
    SportCard
  },
  data () {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      activeLogin: false,
      languages: [
        { code: "en_US", name: "English" },
        { code: "zh_CHT", name: "繁體中文" },
        { code: "zh_CHS", name: "简体中文" },
        { code: "th", name: "ภาษาไทย" },
        { code: "vn", name: "Tiếng Việt" },
        { code: "my", name: "Burmese" },
        { code: "id", name: "Indonesian" }
      ],
      selectedLanguage: { code: "en_US", name: "English" },
      navs: [
        { name: 'betlist', icon: 'list_alt', link: '/betlist' },
        { name: 'statement', icon: 'account_balance_wallet', link: '/statement' },
        { name: 'result', icon: 'assignment', link: '/result' },
        { name: 'settings', icon: 'settings', link: '/settings' }
      ],
      selectedNav: null,
      submiting: false,
      error: null,
      isLogin: true,
      allSports: [],
      currentSport: this.$route.params
    };
  },
  mounted () {
    // Selected language
    this.languages.forEach(e => {
      if (e.code === this.$store.state.locale) {
        this.selectedLanguage = e
      }
    });

    // Load user
    if (this.loggedOn) {
      this.loadUser()
      this.loadFavorites()
    }

    this.$store.dispatch('getSports').then(result => {
      this.allSports = result

      if (!this.isSport(this.currentSport)) {
        var sport
        if (this.allSports[1].ss.length > 0) {
          sport = this.allSports[1]
        }
        else {
          sport = this.allSports[0]
        }

        if (sport.ss.length > 0) {
          this.currentSport = {
            sportsbook: sport.id,
            sportId: sport.ss[0].id,
            betTypes: sport.ss[0].bgs[0].items
          }
        }
      }

      if (this.isSport(this.currentSport)) {
        this.$router.push({ name: 'Hdpou', params: this.currentSport })
      }
    })

    this.$watch(
      'selectedLanguage',
      (newVal, oldVal) => {
        if (oldVal) {
          this.$i18n.locale = newVal.code
          this.$store.commit('locale', newVal.code)

          // Reload
          this.$store.dispatch('getSports').then(result => this.allSports = result)
        }
      },
      { immediate: true }
    )
  },
  computed: {
    loggedOn: function () {
      return this.$store.state.loggedOn;
    },
    active: function () {
      return this.$store.state.active
    },
    title: function () { return this.isLogin ? "login.title" : "reset.title" }
  },
  watch: {
    '$store.state.active': function (newVal) {
      console.log(newVal)
    },
    '$store.state.sb.sports': function (newVal) {
      console.log(newVal)
      if (newVal)
        this.allSports = newVal
    }
  },
  methods: {
    toggleSidebar () {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    isSport (model) {
      return 'sportsbook' in model && 'sportId' in model && 'betTypes' && model
    },
    loginSuccess () {
      this.loadUser()
      this.loadFavorites()
      this.activeLogin = false
    },
    async logout () {
      this.$store.dispatch('logout').then(() => location.href = '/')
      this.isLogin = true;
    },
    async loadUser () {
      this.$store.dispatch('getUser')
    },
    async loadFavorites () {
      this.$store.dispatch('getFavorites')
    }
  }
};
