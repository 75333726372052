import { render, staticRenderFns } from "./Subpage.vue?vue&type=template&id=289a1ef6&"
import script from "./Subpage.vue?vue&type=script&lang=js&"
export * from "./Subpage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QPageContainer} from 'quasar'
component.options.components = Object.assign({QLayout,QHeader,QToolbar,QBtn,QToolbarTitle,QPageContainer}, component.options.components || {})
