module.exports = {
    sb: {
        refresh: "刷新",
        print: "列印",
        empty: "沒有記錄",
        note: {
            title: "注意：:",
            content: [
                "最新未完成投注可能需要一些時間處理，可至投注歷史查詢您的投注紀錄。",
                "顯示時間為GMT-4時區。",
            ]
        },
        sorts: {
            league: "普通排序",
            time: "按時間排序"
        },
        views: {
            single: "單線單盤口",
            double: "雙線雙盤口"
        },
        my_favorites: "我的最愛",
        my_favorites_empty: "您尚未在我的最愛中加入任何賽事。請點選聯賽前方或賽事中的星號將其設為我的最愛。",
        parlay: "混合串關",
        step: "泰式串關",
        win: "贏",
        win_half: "贏一半",
        loss: "輸",
        loss_half: "輸一半",
        draw: "和局",
        from: "來自"
    },
    sportsbook: {
        name: "Wesports",
        early: "早盤",
        today: "今日賽事",
        live: "滾球",
        parlay: "混合串關",
        step: "泰式串關",
        all: "全部"
    },
    login: {
        login_header: "登入",
        login_id: "戶名",
        login_id_error: "請輸入戶名",
        password: "密碼",
        password_error: "請輸入密碼",
        password_help: "請輸入現在的密碼 密碼區分大小寫，長度必須為6至10個字元。",
        remember_me: "記住我?",
        accept: "同意服務協議和用戶隱私政策條款",
        accept_error: "您必須先同意服務協議和用戶隱私政策條款",
        login: "登入",
        logout: "登出",
        reset_header: "設定帳戶",
        nickname: "暱稱",
        nickname_error: "請輸入暱稱",
        nickname_help: "您可以設定暱稱，用於顯示及登入（5 至 15 個字母或數字）。請注意，您僅可以設定暱稱一次。一經設定，就無法修改或取消暱稱。",
        new_password: "新密碼",
        new_password_error: "请输入新密码",
        confirm_password: "再次輸入您的新密碼",
        confirm_password_error: "密碼不符",
        submit: "提交"
    },
    navs: {
        betlist: "投注歷史",
        statement: "帳目",
        result: "賽果",
        settings: "設定"
    },
    user: {
        refresh: "刷新",
        settings: "設定",
        show_balance: "顯示餘額",
        nickname: "暱稱",
        edit: "編輯",
        cash_balance: "現金餘額",
        outstanding: "未結束投注",
        net_position: "Net Position",
        bet_credit: "投注額度",
        given_credit: "信用額度",
        last_login: "最後登入",
        last_transaction: "最後交易",
        password_expiry: "密碼過期"
    },
    betslip: {
        name: "投注單",
        auto_refresh: "自動刷新",
        add_to_parlay: "加入混合串關",
        remove_from_parlay: "從混合串關移除",
        accept_better_odds: "自動接受較佳賠率",
        stake: "投注金額",
        payout: "贏取金額",
        min: "最低投注",
        max: "最高投注",
        process_bet: "下注",
        cancel: "取消",
        confirm_bet: "確認下注?",
        yes: "是",
        no: "否",
        min_stake_alert: "您的投注金額少於最低投注額。",
        no_bet_alert: "您的投注單是空的，請點選畫面上的賠率進行下注。",
        single: "單筆投注",
        parlay: "混合串關",
        step: "泰式串關",
        parlay_add: "加入混合串關",
        parlay_remove: "從混合串關移除",
        // Parlay
        accept_any_odds: "自動接受任何赔率",
        expand_all: "展開全部",
        total_bets: "總注單數",
        total_stake: "總投注額",
        max_payout: "最大贏取金額",
        step_add: "加入泰式串關",
        step_remove: "從泰式串關移除",
        close: "關閉",
        stake_per_bet: "每注投注"
    },
    ticket: {
        id: "ID",
        stake: "投注金額",
        payout: "贏取金額",
        stauts: "狀態",
        timestamp: "交易時間"
    },
    sports: {
        name: "所有運動",
        soccer: "足球",
        basketball: "籃球",
        early: "早盤",
        today: "今日賽事",
        live: "滾球",
        no_matches: "沒有可投注的賽事或遊戲",
        hdpou: "讓球 & 大小",
        match_odds_1x2: "全場 & 半場 獨贏",
        correct_score: "全場 & 半場 波膽",
        odd_even: "全場 & 半場 單/雙",
        total_goal: "全場 & 半場 總進球",
        halftime_fulltime: "半場/全場",
        halftime_fulltime_odd_even: "半場/全場  單/雙",
        first_goal_lat_goal: "全場 & 半場 最先進球/最後進球",
        mix_parlay: "混合串關",
        step: "泰式串關",
        outright: "優勝冠軍"
    },
    betgroups: {
        "1": "讓球 & 大小",
        "2": "全場 & 半場 獨贏",
        "3": "全場 & 半場 波膽",
        "4": "全場 & 半場 單/雙",
        "5": "全場 & 半場 總進球",
        "6": "半場/全場",
        "7": "半場/全場  單/雙",
        "8": "全場 & 半場 最先進球/最後進球",
        "9": "混合串關",
        "10": "泰式串關",
        "11": "優勝冠軍"
    },
    bettypes: {
        'full-time-handicap': "讓球",
        'full-time-handicap-header': "全場讓球",
        'full-time-over/under': "大小盤",
        'full-time-over/under-header': "全場大/小",
        'full-time-1x2': "全場.獨贏盤",
        'full-time-1x2-header': "全場獨贏",
        'half-time-handicap': "上半場讓球",
        'half-time-handicap-header': "上半場讓球",
        'half-time-over/under': "上半場大小盤",
        'half-time-over/under-header': "上半場大/小",
        'half-time-1x2': "上半場.獨贏盤",
        'half-time-1x2-header': "上半場獨贏",
    },
    odds_types: {
        DEC: "歐洲盤",
        MY: "馬來盤",
        HK: "香港盤",
        INDO: "印度盤"
    },
    odds: {
        event: "賽事",
        time: "時間",
        full_time: "全場",
        half_time: "半場",
        title: "赔率",
        draw: "和局",
        under: "小",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "單",
            even: "雙"
        },
        fglg: {
            first_goal: "最先進球",
            last_goal: "最後進球",
            no_goal: "無進球",
        },
        hf: {
            home_home: "主主",
            home_draw: "主和",
            home_away: "主客",
            draw_home: "和主",
            draw_draw: "和和",
            draw_away: "和客",
            away_home: "客主",
            away_draw: "客和",
            away_away: "客客"
        },
        hfoe: {
            odd_odd: "單/單",
            odd_even: "單/雙",
            even_odd: "雙/單",
            even_even: "雙/雙",
        },
        single_line: {
            full_time_handicap: "全場 讓球",
            half_time_handicap: "上半場 讓球",
            handicap: '讓球',
            h: "主",
            home: "主",
            a: "客",
            away: "客",
            goal: "總進球",
            over: "大",
            under: "小"
        }
    },
    alerts: {
        no_matches: "目前沒有可投注的賽事或遊戲，您可先至其他運動或遊戲進行下注，或稍後回來查看。"
    },
    league_filter: {
        leagues: "聯賽",
        title: "選擇聯賽",
        check_all: "全選",
        close: "關閉",
        submit: "提交",
        cancel: "取消"
    },
    month: {
        jan: "一月",
        feb: "二月",
        mar: "三月",
        apr: "四月",
        may: "五月",
        jun: "六月",
        jul: "七月",
        aug: "八月",
        sep: "九月",
        oct: "十月",
        nov: "十一月",
        dec: "十二月"
    },
    week: {
        mon: "一",
        tues: "二",
        web: "三",
        thur: "四",
        fri: "五",
        sat: "六",
        sun: "日",
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日'
    },
    betlist: {
        name: "投注歷史",
        bets: "進行中",
        waiting: "等待中",
        void: "作廢",
        open: "進行中",
        pending: "等待中",
        canceled: "作廢",
        no_bets: "沒有任何進行中的投注",
        no_waiting: "沒有任何等待中的投注",
        no_void: "沒有任何作廢的投注",
        header: {
            no: "編號",
            date: "日期",
            choice: "選擇",
            odds: "賠率",
            stake: "投注金額",
            status: "狀態"
        },
        from: "來自",
        status: {
            void: "已作廢",
            open: "進行中",
            pending: "待確定",
            canceled: "已取消"
        }
    },
    statement: {
        name: "帳目",
        date: "日期",
        remark: "備註",
        turnover: "交易額",
        credit_debit: "支出 / 收入",
        commission: "佣金",
        balance: "餘額",
        opening_balance: "前期餘額",
        betting_statement: "投注帳目",
        outstanding_bet: "未完成交易",
        bet_list: "投注歷史",
        bet_list_no: "編號",
        bet_list_transaction_time: "交易時間",
        bet_list_event: "賽事",
        bet_list_odds: "賠率",
        bet_list_stake: "投注金額",
        bet_list_win_loss: "輸贏",
        bet_list_status: "狀態",
        bet_list_status_running: "進行中",
        bet_list_subtotal: "小計 (贏)",
        bet_list_subtotal_commission: "小計 (佣金)",
        bet_list_total: "總數",
        bet_summary: "投注摘要",
        bet_summary_date: "日期",
        bet_summary_bet_types: "投注類型",
        bet_summary_event: "賽事",
        bet_summary_stake: "投注金額",
        bet_summary_credit_debit: "支出 / 收入"
    },
    result: {
        title: "賽果",
        result: "賽果",
        today: "今日",
        yesterday: "昨日賽事",
        all_leagues: "所有聯賽",
        kickoff_time: "開賽時間",
        match: "賽事",
        first_half: "上半場比分",
        final: "最後賽果",
        status: "狀態",
        note: "注意：",
        note_1: "最新未完成投注可能需要一些時間處理，可至投注歷史查詢您的投注紀錄。",
        note_2: "顯示時間為GMT-4時區。",
        outright: {
            text: "優勝冠軍",
            time: "時間",
            team: "隊名",
            result: "賽果"
        }
    },
    settings: {
        change_account_password: "更改帳戶密碼",
        current_password: "目前的密碼",
        current_password_error: "請輸入現在的密碼",
        new_password: "新密碼",
        new_password_error: "请输入新密码",
        confirm_password: "再次輸入您的新密碼",
        confirm_password_error: "密碼不符",
        password_help: "密碼區分大小寫，長度必須為6至20個字元。",
        change_account_nickname: "更改帳戶暱稱",
        nickname: "暱稱",
        nickname_error: "請輸入暱稱",
        nickname_help: "您可以設定暱稱，用於顯示及登入（5 至 20 個字母或數字）。請注意，您僅可以設定暱稱一次。一經設定，就無法修改或取消暱稱。",
        submit: "提交",
        language: "多语言",
        odds_type: "盤口类型",
        change_password: "更改密碼"
    }
}