//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Betlist",
  props: {
    selectedIndex: {
      type: Number
    }
  },
  data () {
    return {
      tabs: [
        {
          name: "bets",
          icon: "done_all",
          tickets: [],
          status: "open",
          activeTimer: false,
          refresh: {
            enable: true,
            countDown: 5,
            timer: null
          }
        },
        {
          name: "waiting",
          icon: "donut_large",
          tickets: [],
          status: "pending",
          activeTimer: true,
          refresh: {
            enable: true,
            countDown: 5,
            timer: null
          }
        },
        {
          name: "void",
          icon: "cancel",
          tickets: [],
          status: "canceled",
          activeTimer: false,
          refresh: {
            enable: false,
            countDown: 5,
            timer: null
          }
        }
      ],
      current: 0,
      loading: false,
      message: null,
      countDown: 5
    };
  },
  mounted () {
    if (this.selectedIndex && (this.selectedIndex >= 0 && this.selectedIndex <= 2)) {
      this.current = this.selectedIndex
    }

    this.load(this.tabs[this.current])

    this.$watch('$i18n.locale', (newVal, oldVal) => {
      if (oldVal) {
        this.load(this.tabs[this.current])
      }
    }, { immediate: true })
  },
  watch: {
    current: function (newVal, oldVal) {
      if (oldVal) {
        try {
          this.stopRefresh(this.tabs[oldVal])
          // eslint-disable-next-line no-empty
        } catch (e) { }
      }

      if (newVal >= 0 && newVal <= 2) {
        this.load(this.tabs[newVal]);
      }
    },
    "$store.state.active": function (newVal) {
      if (newVal === "betlist") {
        var active = this.$store.state.betlist;
        console.log({ current: this.current, active: active })
        if (this.current === active) {
          this.load(this.tabs[active]);
        } else {
          this.current = active
        }
      }
    }
  },
  methods: {
    load: function (tab) {
      console.log(`Fetching ${tab.name} betlist.`)
      this.loading = true;
      this.message = null;
      var hasWaiting = tab.name === 'waiting' && tab.tickets.length > 0
      this.$store
        .dispatch("betlist", { status: tab.status })
        .then(data => {
          tab.tickets = data;
          this.loading = false;

          if (tab.refresh.enable && tab.tickets.filter(t => t.status === 'PENDING').length > 0) {
            this.startRefresh(tab);
          } else {
            this.stopRefresh(tab)
          }

          if (hasWaiting && tab.tickets.length == 0) {
            this.selectedTab(tab, 0)
          }
        })
        .catch(err => {
          this.message = err.message;
          this.loading = false;
        });
    },
    selectedTab: function (tab, index) {
      this.current = index;
      this.$store.commit('betlist', this.current)
    },
    startRefresh: function (tab) {
      let refresh = tab.refresh
      if (!refresh.timer) {
        refresh.timer = setInterval(() => {
          if (refresh.countDown > 0) {
            refresh.countDown--
            if (refresh.countDown === 0) {
              refresh.countDown = 5
              this.load(tab);
            }
          }
        }, 1000);
      }
    },
    stopRefresh: function (tab) {
      if (tab.refresh.timer) {
        clearInterval(tab.refresh.timer);
        tab.refresh = Object.assign(tab.refresh, { timer: null, countDown: 5 })
      }
    }
  }
};
