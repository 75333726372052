module.exports = {
    sb: {
        refresh: "Refresh",
        print: "Cetak Halaman Ini",
        empty: "No information is available",
        note: {
            title: "Catatan:",
            content: [
                "Taruhan yang masih berjalan mungkin perlu waktu untuk diproses. Buka Daftar Taruhan untuk melihat taruhan masih berjalan terbaru Anda.",
                "Mohon diperhatikan bahwa waktu yang ditampilkan berdasarkan waktu GMT-4"
            ]
        },
        sorts: {
            league: "Penyortiran normal",
            time: "Sortir Menurut Waktu"
        },
        views: {
            single: "Spasi Tunggal",
            double: "Spasi Ganda"
        },
        my_favorites: "Favorit Saya",
        my_favorites_empty: "Favorit Saya kosong. Harap klik bintang yang digarisbawahi sebelum pertandingan atau liga untuk menambahkannya di sini.",
        parlay: "Mix Parlay",
        step: "Step",
        win: "Win",
        win_half: "Win Half",
        loss: "Loss",
        loss_half: "Loss Half",
        draw: "Draw",
        from: "From"
    },
    sportsbook: {
        name: "Wesports",
        early: "Awal",
        today: "Hari Ini",
        live: "Langsung",
        parlay: "Taruhan Campuran",
        step: "Step",
        all: "Semua"
    },
    login: {
        login_header: "Login",
        login_id: "Nama pengguna",
        login_id_error: "Please input your login Id",
        password: "Kata Sandi",
        password_error: 'Please input your password',
        password_help: 'Password is case sensitive and must be 6 to 20 characters in length',
        remember_me: "Ingat Saya?",
        accept: "I accept the license and terms",
        accept_error: "You need to accept the license and terms first",
        login: "Login",
        logout: "Logout",
        reset_header: "Set your account",
        nickname: "Nickname",
        nickname_error: "Please enter nickname.",
        nickname_help: "You can set nickname for display and login (5 to 15 alphabets or digits). Please note that you can set this nickname only once. You cannot modify or cancel it once it’s been set.",
        new_password: "New Password",
        new_password_error: "Please enter new password",
        confirm_password: "Confirm Password",
        confirm_password_error: "Passwords do not match",
        submit: "Submit"
    },
    navs: {
        betlist: "Daftar Taruhan",
        statement: "Laporan",
        result: "Hasil",
        settings: "Pengaturan"
    },
    user: {
        refresh: "Refresh",
        settings: "Pengaturan",
        show_balance: "Tampilkan Saldo",
        nickname: "Saldo Tunai",
        edit: "Edit",
        cash_balance: "Saldo Tunai",
        outstanding: "Taruhan Berjalan",
        net_position: "Net Position",
        bet_credit: "Kredit Tersedia",
        given_credit: "Kredit Yang Diberikan",
        last_login: "Login Terakhir",
        last_transaction: "Transaksi Terakhir",
        password_expiry: "Tanggal Kadaluwarsa Kata Sandi"
    },
    betslip: {
        name: "Slip taruhan",
        auto_refresh: "Refresh Otomatis",
        add_to_parlay: "Tambahkan ke Parlay",
        remove_from_parlay: "Remove from Parlay",
        accept_better_odds: "Terima Odds yang Lebih Baik",
        stake: "Taruhan",
        payout: "Pembayaran",
        min: "Mnt",
        max: "Maks",
        process_bet: "Proses Taruhan",
        cancel: "Batal",
        confirm_bet: "Konfirmasi Taruhan?",
        yes: "Ya",
        no: "Tidak",
        min_stake_alert: "Jumlah taruhan Anda kurang dari jumlah taruhan min.",
        no_bet_alert: "Slip taruhan Anda kosong, klik odd pada tabel untuk membuat pilihan taruhan.",
        single: "Single",
        parlay: "Parlay",
        step: "Step",
        parlay_add: "Add to Parlay",
        parlay_remove: "Remove from Parlay",
        // Parlay
        accept_any_odds: "Terima Otomatis Odd Apa Saja",
        expand_all: "Perluas Semua",
        total_bets: "Total Taruhan",
        total_stake: "Total Taruhan",
        max_payout: "Pembayaran Maks",
        step_add: "Add to Step",
        step_remove: "Remove from Step",
        close: "Close"
    },
    ticket: {
        id: "ID",
        stake: "Taruhan",
        payout: "Pembayaran",
        stauts: "Status",
        timestamp: "Waktu Trans."
    },
    sports: {
        name: "Semua Olahraga",
        soccer: "Sepak Bola",
        basketball: "Bola Basket",
        early: "Awal",
        today: "Hari Ini",
        live: "Langsung",
        no_matches: "No matches available",
        hdpou: "HDP & OU",
        match_odds_1x2: "Match Odds 1x2",
        correct_score: "Skor Yang Tepat",
        odd_even: "Odd/Even",
        total_goal: "Total Goal",
        halftime_fulltime: "Babak Pertama/Waktu Penuh",
        halftime_fulltime_odd_even: "Babak Pertama / Babak Penuh Ganjil/Genap",
        first_goal_lat_goal: "Gol Pertama/Gol Terakhir",
        mix_parlay: "Taruhan Campuran",
        step: "Step",
        outright: "Outright"
    },
    betgroups: {
        "1": "HDP & OU",
        "2": "Match Odds 1x2",
        "3": "Skor Yang Tepat",
        "4": "Odd/Even",
        "5": "Total Goal",
        "6": "Babak Pertama/Waktu Penuh",
        "7": "Babak Pertama / Babak Penuh Ganjil/Genap",
        "8": "Gol Pertama/Gol Terakhir",
        "9": "Taruhan Campuran",
        "10": "Step",
        "11": "Outright"
    },
    bettypes: {
        'full-time-handicap': "Waktu Penuh Handicap",
        'full-time-handicap-header': "FT.HDP",
        'full-time-over/under': "Waktu Penuh Over/Under (Di Atas/ Di Bawah)",
        'full-time-over/under-header': "FT.O/U",
        'full-time-1x2': "Waktu Penuh 1X2",
        'full-time-1x2-header': "FT.1X2",
        'half-time-handicap': "Babak Pertama Handicap",
        'half-time-handicap-header': "1H.HDP",
        'half-time-over/under': "Babak Pertama Over/Under (Di Atas/ Di Bawah)",
        'half-time-over/under-header': "1H.O/U",
        'half-time-1x2': "Babak Pertama 1X2",
        'half-time-1x2-header': "1H.1X2"
    },
    odds_types: {
        DEC: "Decimal Odds",
        MY: "Malay Odds",
        HK: "Hong Kong Odds",
        INDO: "Indonesia Odds"
    },
    odds: {
        event: "Permainan",
        time: "Waktu",
        full_time: "Waktu Penuh",
        half_time: "Babak Pertama",
        title: "Odds",
        draw: "Seri",
        under: "u",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "Ganjil",
            even: "Genap"
        },
        fglg: {
            first_goal: "Gol Pertama",
            last_goal: "Gol Terakhir",
            no_goal: "Tidak Ada Gol",
        },
        hf: {
            home_home: "HH",
            home_draw: "HD",
            home_away: "HA",
            draw_home: "DH",
            draw_draw: "DD",
            draw_away: "DA",
            away_home: "AH",
            away_draw: "AD",
            away_away: "AA"
        },
        hfoe: {
            odd_odd: "Ganjil/Ganjil",
            odd_even: "Ganjil/Genap",
            even_odd: "Genap/Ganjil",
            even_even: "Genap/Genap",
        },
        single_line: {
            full_time_handicap: "Waktu Penuh Handicap",
            half_time_handicap: "Babak Pertama Handicap",
            handicap: 'HDP',
            h: "H",
            home: "Tim Kandang",
            a: "A",
            away: "Tim Tandang",
            goal: "Gol",
            over: "Di Atas",
            under: "Di Bawah"
        }
    },
    alerts: {
        no_matches: "Tidak ada acara atau game yang tersedia saat ini, harap kunjungi acara olahraga atau game lain dan kembali nanti."
    },
    league_filter: {
        leagues: "Liga",
        title: "Pilih Liga",
        check_all: "Periksa Semua",
        close: "Tutup",
        submit: "Go",
        cancel: "Batal"
    },
    month: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec"
    },
    week: {
        mon: "Sen",
        tues: "Sel",
        web: "Rab",
        thur: "Kam",
        fri: "Jum",
        sat: "Sab",
        sun: "Ming",
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    },
    betlist: {
        name: "Daftar Taruhan",
        bets: "Taruhan",
        waiting: "Menunggu",
        void: "Batal",
        open: "Berjalan",
        pending: "Menunggu",
        canceled: "Batal",
        no_bets: "No Running Bet",
        no_waiting: "Tidak Ada Taruhan Menunggu",
        no_void: "Tidak Ada Taruhan Yang Gugur",
        header: {
            no: "No.",
            date: "Tanggal",
            choice: "Pilihan",
            odds: "Odd",
            stake: "Taruhan",
            status: "Status"
        },
        from: "From",
        status: {
            void: "Batal",
            open: "Berjalan",
            pending: "Menunggu",
            canceled: "Batal"
        }
    },
    statement: {
        name: "Laporan",
        date: "Tanggal",
        remark: "Keterangan",
        turnover: "Omset",
        credit_debit: "Kredit / Debit",
        commission: "Komisi",
        balance: "Saldo",
        opening_balance: "Saldo Awal",
        betting_statement: "Laporan Taruhan",
        outstanding_bet: "Taruhan yang Belum Diselesaikan",
        bet_list: "Daftar Taruhan",
        bet_list_no: "No.",
        bet_list_transaction_time: "Waktu Trans.",
        bet_list_event: "Permainan",
        bet_list_odds: "Odds",
        bet_list_stake: "Taruhan",
        bet_list_win_loss: "Menang / Kalah",
        bet_list_status: "Status",
        bet_list_status_running: "Berjalan",
        bet_list_subtotal: "Subtotal (Menang)",
        bet_list_subtotal_commission: "Subtotal (Komisi)",
        bet_list_total: "Total",
        bet_summary: "Bet Summary",
        bet_summary_date: "Tanggal",
        bet_summary_bet_types: "Tipe Taruhan",
        bet_summary_event: "Permainan",
        bet_summary_stake: "Taruhan",
        bet_summary_credit_debit: "Kredit / Debit"
    },
    result: {
        title: "Hasil",
        result: "Hasil",
        today: "Hari Ini",
        yesterday: "Kemarin",
        all_leagues: "Semua Liga",
        kickoff_time: "Waktu Permainan Dimulai",
        match: "Permainan",
        first_half: "Skor Babak Pertama",
        final: "Skor Akhir",
        status: "Status",
        outright: {
            text: "Outright",
            time: "Waktu",
            team: "Tim",
            result: "Hasil"
        }
    },
    settings: {
        change_account_password: "Ubah Kata Sandi Akun",
        current_password: "Kata sandi saat ini",
        current_password_error: "Harap masukkan kata sandi saat ini",
        new_password: "Kata Sandi Baru",
        new_password_error: "Harap masukkan kata sandi baru",
        confirm_password: "Konfirmasi Kata Sandi Baru",
        confirm_password_error: "Kata sandi tidak cocok",
        password_help: "Kata sandi peka huruf besar dan kecil dan panjangnya harus 6 hingga 20 karakter.",
        change_account_nickname: "Ubah Nama Panggilan Akun",
        nickname: "Nama Panggilan",
        nickname_error: "Harap masukkan nama panggilan",
        nickname_help: "Anda bisa mengatur nama panggilan untuk tampilan dan log masuk (5 hingga 20 huruf atau angka). Harap diperhatikan, Anda hanya bisa mengatur nama panggilan ini satu kali. Anda tidak dapat memodifikasi atau membatalkannya setelah nama panggilan itu diatur.",
        submit: "Ajukan"
    },
    combos: {
        single: "Single",
        double: "Double",
        treble: "Treble",
        fold4: "4-Fold",
        fold5: "5-Fold",
        fold6: "6-Fold",
        fold7: "7-Fold",
        fold8: "8-Fold",
        fold9: "9-Fold",
        fold10: "10-Fold",
        fold11: "11-Fold",
        fold12: "12-Fold",
        fold13: "13-Fold",
        fold14: "14-Fold",
        fold15: "15-Fold",
        fold16: "16-Fold",
        fold17: "17-Fold",
        fold18: "18-Fold",
        fold19: "19-Fold",
        fold20: "20-Fold"
    }
}