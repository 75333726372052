//
//
//
//
//
//
//
//
//

export default {
  name: 'Odds',
  data () {
    return {
      indicator: ''
    }
  },
  props: {
    marketId: Number,
    handicap: Number,
    odds: Object
  },
  watch: {
    odds: function (newVal, oldVal) {
      if (oldVal) {
        this.indicator = newVal.i == 0 || newVal.i === oldVal.i ? '' : newVal.i > 0 ? 'up' : 'down'

        if (this.indicator.length) {
          window.setTimeout(() => this.indicator = '', 5000);
        }
      }
    }
  },
  methods: {
    activeOdds: function (marketId, handicap, stakeNumber, odds) {
      let oddsType = 'DEC'
      let val = { activeOdds: { marketId, handicap, stakeNumber, odds, oddsType } }
      console.log(val)
      this.$store.commit("sb", val)
    }
  }
}
