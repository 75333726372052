//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Match from '../components/Match'
import Hdpou from '../components/Hdpou'
import MatchOdds from '../components/MatchOdds'
import CorrectScore from '../components/CorrectScore'
import OddEven from '../components/OddEven'
import TotalGoal from '../components/TotalGoal'
import HalfTimeFullTime from '../components/HalfTimeFullTime'
import HalfTimeFullTimeOddEven from '../components/HalfTimeFullTimeOddEven'
import FirstGoalLastGoal from '../components/FirstGoalLastGoal'
import Betslip from '../components/Betslip'
import BetslipTab from '../components/BetslipTab'
import BetlistTab from '../components/BetlistTab'

// const months = ['month.jan', 'month.feb', 'month.mar', 'month.apr', 'month.may', 'month.jun', 'month.jul', 'month.aug', 'month.sep', 'month.oct', 'month.nov', 'month.dec']
// const weeks = ['week.sun', 'week.mon', 'week.tues', 'week.web', 'week.thur', 'week.fri', 'week.sat']
const intervals = { 0: 30000, 1: 10000, 2: 30000, 3: 60000 }

export default {
  name: 'Home',
  components: {
    Match,
    Betslip,
    BetslipTab,
    BetlistTab
  },
  data () {
    return {
      tab: 'soccer',
      sportTab: "today",
      betslipDialog: false,
      sports: [],
      timestamp: 0,
      sportsbook: ['', 'live', 'today', 'early'],
      // betTypes: '11002,11004,11005,11001,11003,11008',
      betTypes: '11005,11008',
      oddsTypes: [
        { code: "DEC", name: "Decimal Odds" },
        { code: "MY", name: "Malay Odds" },
        { code: "HK", name: "Hong Kong Odds" },
        { code: "INDO", name: "Indonesia Odds" }
      ],
      selectedOddsType: this.$store.state.profiles.oddsType,
      selectedTime: this.$route.params.sportsbook,
      loading: false,
      clock: null,
      checkedAllLeauge: false,
      checkedLeagues: [],
      sorts: [
        { name: 'league', value: 1 },
        { name: 'time', value: 2 }
      ],
      selectedSort: this.$store.state.profiles.sort,
      favorites: this.$store.state.profiles.favorites,
      odds: {
        hdpou: {
          groupId: 1,
          component: Hdpou,
          betTypes: '11002,11004,11005,11001,11003,11008'
        },
        '1x2': {
          groupId: 2,
          component: MatchOdds,
          betTypes: '11005,11008'
        },
        cs: {
          groupId: 3,
          component: CorrectScore,
          betTypes: '11007'
        },
        oe: {
          groupId: 4,
          component: OddEven,
          betTypes: '11006,11016'
        },
        tg: {
          groupId: 5,
          component: TotalGoal,
          betTypes: '11009,11017'
        },
        htft: {
          groupId: 6,
          component: HalfTimeFullTime,
          betTypes: '11011'
        },
        hfoe: {
          groupId: 7,
          component: HalfTimeFullTimeOddEven,
          betTypes: '11010'
        },
        fglg: {
          groupId: 8,
          component: FirstGoalLastGoal,
          betTypes: '11012,11018'
        },
        parlay: {
          groupId: 9,
          component: Hdpou,
          allowParlay: true,
          betTypes: '11002,11004,11005,11001,11003,11008'
        },
        step: {
          groupId: 10,
          component: Hdpou,
          allowParlay: true,
          betTypes: '11002,11004,11005,11001,11003,11008'
        }
      },
      currentOdds: null,
    }
  },
  beforeRouteUpdate (to, from, next) {
    this.sports = []
    this.timestamp = 0
    console.log({ router: to.params })
    this.currentOdds = this.odds[to.params.layout || 'hdpou']
    this.$store.commit('title', this.$t(`betgroups.${this.currentOdds.groupId}`))
    this.stopRefresh()
    this.loadOdds(to.params)
    next()
  },
  mounted () {
    this.currentOdds = this.odds.hdpou
    this.$store.commit('title', this.$t(`betgroups.${this.currentOdds.groupId}`))

    this.$watch('tab', (newVal) => {
      console.log({ tab: newVal })
      if (newVal) {
        let title = newVal === 'betslip' ? this.$t('betslip.name') : (newVal === 'betlist' ? this.$t('betlist.name') : this.$t(`betgroups.${this.currentOdds.groupId}`))
        this.$store.commit('title', title)
        this.stopRefresh()
        if (newVal === 'soccer') {
          this.timestamp = 0
          this.loadOdds({ sportsbook: (this.sportTab === 'early' ? 3 : (this.sportTab === 'live' ? 1 : 2)), sportId: 1 })
        }
      }
    }, { immediate: true })

    this.$watch('sportTab', (newVal) => {
      console.log({ sportTab: newVal })
      if (newVal) {
        this.$store.commit('sb', { current: newVal })
        this.stopRefresh()

        let sportsbook = newVal === 'early' ? 3 : (newVal === 'live' ? 1 : 2)
        let sportId = this.$route.params.sportId || 1
        let layout = this.$route.params.layout || 'hdpou'
        // this.$router.push(`/m/${sportsbook}/${sportId}/${layout}`)
        this.timestamp = 0
        this.loadOdds({ sportsbook, sportId, layout })
      }
    }, { immediate: true })

    this.$watch('$store.state.sb.activeOdds', (odds) => {
      if (odds) {
        this.$store.dispatch('checkBetting', odds)
          .then(ticket => {
            console.log(ticket)
            this.$store.commit('betslip2', { single: ticket })
            this.betslipDialog = true
          })
          .catch(err => {
            this.$q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'info',
              position: 'top',
              message: err.message
            })
          })
      }
    }, { immediate: true })
  },
  destroyed () {
    console.log("Home destroyed")
    this.stopRefresh()
  },
  computed: {
    activeTicket: function () {
      return this.$store.state.betslip2.single;
    },
    parlayCount: function () {
      return this.$store.state.betslip2.parlay ? this.$store.state.betslip2.parlay.selections.length : 0
    },
    stepCount: function () {
      return this.$store.state.betslip2.step ? this.$store.state.betslip2.step.selections.length : 0
    }
  },
  methods: {
    closeBetslipDialog: function () {
      this.$store.commit('betslip2', { single: { type: 1, selections: [] } })
      this.betslipDialog = false
      console.log("Close Betslip")
    },
    loadOdds: function (model) {
      this.loading = !('timestamp' in model)
      this.$store.dispatch('loadOdds', Object.assign(model, { timestamp: this.timestamp, oddsType: this.selectedOddsType, sort: this.selectedSort, betTypes: this.currentOdds.betTypes }))
        .then(data => {
          if (data.sports.length > 0) {
            if (this.sports.length === 0 || this.timestamp === 0) {
              this.sports = data.sports
            }
            else {
              data.sports.forEach(sport => {
                // If live then remove from today or early
                if (sport[0] === 1) {
                  var leagues = sport.slice(5).map(league => [league[0], league.slice(4).map(match => match[0])])
                  leagues.forEach(league => {
                    for (var i = 0; i < this.sports.length; i++) {
                      if (this.sports[i][0] !== 1) {
                        for (var l = 5; l < this.sports[i].length; l++) {
                          if (league[0] === this.sports[i][l][0]) {
                            league[1].forEach(match => {
                              for (var m = 4; m < this.sports[i][l].length; m++) {
                                if (match === this.sports[i][l][m][0]) {
                                  this.sports[i][l].splice(m, 1)
                                }
                              }
                            })

                            // If no matches remove league
                            if (this.sports[i][l].length < 5) {
                              this.sports[i].splice(l, 1)
                            }

                            break;
                          }
                        }
                      }
                    }
                  })
                }

                var i = this.sports.findIndex(t => t[0] === sport[0])
                if (i === -1) {
                  var t = this.sports.findIndex(t => t[0] > sport[0]);
                  // Filter close
                  var newLeagues = sport.slice(5).map(league => league.slice(0, 4).concat(league.slice(4).filter(m => m.length > 16)))
                  sport = sport.slice(0, 5).concat(newLeagues.filter(l => l.length > 4))

                  if (sport.length > 5) {
                    if (t === -1) {
                      this.sports.push(sport)
                    } else {
                      this.sports.splice(t, 0, sport)
                    }
                  }
                } else {
                  var changedLeagues = sport.slice(5).map(league => {
                    var changedMatches = league.slice(4)
                    var oldMatches = this.sports[i].filter((v, i) => i > 4 && v[0] === league[0])
                      .flatMap(e => e)
                      .filter((v, i) => i > 3 && changedMatches.findIndex(m => m[0] == v[0]) === -1)
                    var newMatches = changedMatches.filter(m => m.length > 16)
                      .concat(oldMatches).sort((m1, m2) => m1[14] - m2[14])
                    var changedLeague = league.slice(0, 4).concat(newMatches)

                    return changedLeague;
                  })

                  sport = sport.slice(0, 5).concat(
                    changedLeagues.filter(l => l.length > 4).concat(this.sports[i].filter((v, i) => i > 4 && changedLeagues.findIndex(l => l[0] === v[0]) == -1))
                      .sort((l1, l2) => l1[3] - l2[3])
                  )

                  this.sports.splice(i, 1, sport)
                }
              })
            }
          }

          this.timestamp = data.timestamp
          this.loading = false

          // Start clock
          if (this.clock == null) {
            let sb = this.sports.length > 0 ? Math.min(...this.sports.map(m => m[0])) : 0
            let timeout = intervals[sb]
            console.log(`Starting clock and reload odds at ${timeout / 1000}s after.`)
            this.clock = window.setInterval(() => {
              console.log(`Fetch odds at ${this.timestamp}.`)
              this.loadOdds(model)
            }, timeout)
          }
        })
    },
    checkedLeague: function (id) {
      return this.checkedLeagues.length === 0 || this.checkedLeagues.filter(val => val[0] === id).length > 0
    },
    stopRefresh () {
      if (this.clock) {
        window.clearInterval(this.clock)
        this.clock = null
      }
    }
  }
}
