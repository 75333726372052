import { render, staticRenderFns } from "./Hdpou.vue?vue&type=template&id=0272706a&"
import script from "./Hdpou.vue?vue&type=script&lang=js&"
export * from "./Hdpou.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QSeparator} from 'quasar'
component.options.components = Object.assign({QCard,QCardSection,QSeparator}, component.options.components || {})
