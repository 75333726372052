import { render, staticRenderFns } from "./Betslip.vue?vue&type=template&id=40d74064&scoped=true&"
import script from "./Betslip.vue?vue&type=script&lang=js&"
export * from "./Betslip.vue?vue&type=script&lang=js&"
import style0 from "./Betslip.vue?vue&type=style&index=0&id=40d74064&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d74064",
  null
  
)

export default component.exports
import {QCard,QSpinnerDots,QCardSection,QItemLabel,QCheckbox,QTabPanels,QTabPanel,QForm,QInput,QToggle,QBtn,QList,QExpansionItem,QItemSection,QField} from 'quasar'
component.options.components = Object.assign({QCard,QSpinnerDots,QCardSection,QItemLabel,QCheckbox,QTabPanels,QTabPanel,QForm,QInput,QToggle,QBtn,QList,QExpansionItem,QItemSection,QField}, component.options.components || {})
