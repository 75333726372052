import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=258108df&"
import script from "./Home.vue?vue&type=script&lang=js&"
export * from "./Home.vue?vue&type=script&lang=js&"
import style0 from "./Home.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QTabPanels,QTabPanel,QTabs,QTab,QSpinnerDots,QList,QVirtualScroll,QExpansionItem,QCard,QCardSection,QSeparator,QDialog,QBtn,QBadge,QLayout,QItem,ClosePopup} from 'quasar'
component.options.components = Object.assign({QPage,QTabPanels,QTabPanel,QTabs,QTab,QSpinnerDots,QList,QVirtualScroll,QExpansionItem,QCard,QCardSection,QSeparator,QDialog,QBtn,QBadge,QLayout,QItem}, component.options.components || {})
component.options.directives = Object.assign({ClosePopup}, component.options.directives || {})
