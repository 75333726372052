module.exports = {
    sb: {
        refresh: "รีเฟรช",
        print: "ถ่ายสำเนาหน้านี้",
        empty: "No information is available",
        note: {
            title: "เมื่อวานนี้:",
            content: [
                "ยอดเดิมพันค้างชำระอาจต้องใช้เวลาสักครู่เพื่อประมวลผล กรุณาไปที่ รายการวางเดิมพัน เพื่อดูยอดเดิมพันค้างชำระล่าสุดของคุณ",
                "โปรดทราบว่าเวลาที่แสดงอยู่บนพื้นฐานGMT -4ชั่วโมง."
            ]
        },
        sorts: {
            league: "เรียงอย่างธรรมดา",
            time: "เรียงตามเวลา"
        },
        views: {
            single: "แถวเดี่ยว",
            double: "แถวคู่"
        },
        my_favorites: "รายการโปรดของฉัน",
        my_favorites_empty: "รายการชื่นชอบว่างเปล่า กรุณาคลิกดาวที่ระบุไว้ก่อนการแข่งขันหรือลีก เพื่อเพิ่มรายการดังกล่าวที่นี่",
        parlay: "พาร์เลย์",
        step: "สเต็ป",
        win: "Win",
        win_half: "Win Half",
        loss: "Loss",
        loss_half: "Loss Half",
        draw: "Draw",
        from: "From"
    },
    sportsbook: {
        name: "Wesports",
        early: "ล่วงหน้า",
        today: "วันนี้",
        live: "สด",
        parlay: "มิกซ์ พาร์เลย์",
        step: "สเต็ป",
        all: "ทั้งหมด"
    },
    login: {
        login_header: "เข้าสู่ระบบ",
        login_id: "ชื่อผู้ใช้",
        login_id_error: "Please input your login Id",
        password: "รหัสผ่าน",
        password_error: 'Please input your password',
        password_help: "กรุณากรอกรหัสผ่านปัจจุบัน รหัสผ่านต้องตรงตามตัวพิมพ์เล็ก/ใหญ่ และมีความยาวระหว่าง 6 ถึง 10 ตัวอักษร",
        remember_me: "จำการตั้งค่า",
        accept: "I accept the license and terms",
        accept_error: "You need to accept the license and terms first",
        login: "เข้าสู่ระบบ",
        logout: "ออกจากระบบ",
        reset_header: "Set your account",
        nickname: "ชื่อเล่น",
        nickname_error: "กรุณากรอกชื่อเล่น",
        nickname_help: "คุณสามารถตั้งชื่อเล่นสำหรับการแสดงผลและเข้าสู่ระบบ (ตัวอักษรหรือตัวเลข 5-15 ตัว) โปรดทราบว่า คุณตั้งชื่อเล่นนี้ได้ครั้งเดียวเท่านั้น คุณไม่สามารถแก้ไขหรือยกเลิกชื่อเล่นนี้เมื่อตั้งค่าแล้ว",
        new_password: "รหัสผ่านใหม่",
        new_password_error: "กรุณากรอกรหัสผ่านใหม่",
        confirm_password: "ยืนยันรหัสในการเข้าระบบ",
        confirm_password_error: "รหัสผ่านไม่ตรงกัน",
        submit: "ตกลง"
    },
    navs: {
        betlist: "รายการ",
        statement: "งบดุล",
        result: "ผลลัพธ์",
        settings: "การตั้งค่า"
    },
    user: {
        refresh: "รีเฟรช",
        settings: "การตั้งค่า",
        show_balance: "แสดงยอดคงเหลือเดิมพัน",
        nickname: "ชื่อเล่น",
        edit: "แก้ไข",
        cash_balance: "เงิน ได้/เสีย",
        outstanding: "รายการที่ค้างไว้",
        net_position: "Net Position",
        bet_credit: "เครดิตเดิมพัน",
        given_credit: "ให้เครดิต",
        last_login: "การเข้าระบบครั้งสุดท้าย",
        last_transaction: "รายการเล่นสุดท้าย",
        password_expiry: "วันหมดอายุของรหัส"
    },
    betslip: {
        name: "ใบเดิมพัน",
        auto_refresh: "รีเฟรชอัตโนมัติ",
        add_to_parlay: "เพิ่มไปยังพาร์เลย์",
        remove_from_parlay: "Remove from Parlay",
        accept_better_odds: "ยอมรับอัตราเดิมพันที่ดีกว่า",
        stake: "เงินที่วางเดิมพัน",
        payout: "จ่าย",
        min: "ขั้นต่ำ",
        max: "มากที่สุด",
        process_bet: "ทำรายการ",
        cancel: "ยกเลิก",
        confirm_bet: "ยืนยันการเดิมพันหรือไม่?",
        yes: "ใช่",
        no: "ไม่ใช่",
        min_stake_alert: "เงินเดิมพันของคุณต่ำกว่าเงินเดิมพันขั้นต่ำสุด",
        no_bet_alert: "ใบเดิมพันของคุณว่างเปล่า กรุณาคลิกที่อัตราเดิมพันในตารางเพื่อเลือกการเดิมพัน",
        single: "Single",
        parlay: "พาร์เลย์",
        step: "สเต็ป",
        parlay_add: "เพิ่มไปยังพาร์เลย์",
        parlay_remove: "Remove from Parlay",
        // Parlay
        accept_any_odds: "ยอมรับการเดิมพันโดยอัตโนมัติ",
        expand_all: "เลือกทั้งหมด",
        total_bets: "เดิมพันรวม",
        total_stake: "เงินวางเดิมพันทั้งหมด",
        max_payout: "อัตราจ่ายมากที่สุด",
        step_add: "Add to Step",
        step_remove: "Remove from Step",
        close: "Close"
    },
    ticket: {
        id: "ID",
        stake: "เงินที่วางเดิมพัน",
        payout: "จ่าย",
        stauts: "สถานะ",
        timestamp: "หมายเลขบิล"
    },
    sports: {
        name: "กีฬาทั้งหมด",
        soccer: "ฟุตบอล",
        basketball: "บาสเก็ตบอล",
        early: "ล่วงหน้า",
        today: "วันนี้",
        live: "สด",
        no_matches: "No matches available",
        hdpou: "แฮนดิแคป & มากกว่า/น้อยกว่า",
        match_odds_1x2: "เต็มเวลา & ครึ่งแรก ราคาพูล",
        correct_score: "คะแนนที่ถูกต้อง",
        odd_even: "คี่/คู่",
        total_goal: "จำนวนรวมของประตู",
        halftime_fulltime: "ครึ่งแรก/เต็มเวลา",
        halftime_fulltime_odd_even: "ครึ่งแรก/เต็มเวลา คี่/คู่",
        first_goal_lat_goal: "ประตูแรก/ประตูท้าย",
        mix_parlay: "มิกซ์ พาร์เลย์",
        step: "สเต็ป",
        outright: "เอาท์ไรท์"
    },
    betgroups: {
        "1": "แฮนดิแคป & มากกว่า/น้อยกว่า",
        "2": "เต็มเวลา & ครึ่งแรก ราคาพูล",
        "3": "คะแนนที่ถูกต้อง",
        "4": "คี่/คู่",
        "5": "จำนวนรวมของประตู",
        "6": "ครึ่งแรก/เต็มเวลา",
        "7": "ครึ่งแรก/เต็มเวลา คี่/คู่",
        "8": "ประตูแรก/ประตูท้าย",
        "9": "มิกซ์ พาร์เลย์",
        "10": "สเต็ป",
        "11": "เอาท์ไรท์"
    },
    bettypes: {
        'full-time-handicap': "แฮนดิแคป",
        'full-time-handicap-header': "แฮนดิแคปเต็มเวลา",
        'full-time-over/under': "มากกว่า/น้อยกว่า",
        'full-time-over/under-header': "เต็มเวลาสูง/ต่ำ",
        'full-time-1x2': "ราคาพูล",
        'full-time-1x2-header': "เต็มเวลา ราคาพูล",
        'half-time-handicap': "แฮนดิแคป (ครึ่งแรก)",
        'half-time-handicap-header': "แฮนดิแคปครึ่งแรก",
        'half-time-over/under': "มากกว่า/น้อยกว่า (ครึ่งแรก)",
        'half-time-over/under-header': "ครึ่งแรกสูง/ต่ำ",
        'half-time-1x2': "แพ้/ชนะ (ครึ่งแรก)",
        'half-time-1x2-header': "ครึ่งแรก ราคาพูล"
    },
    odds_types: {
        DEC: "การเดิมพันแบบมีทศนิยม",
        MY: "การเดิมพันแบบมาเลย์",
        HK: "การเดิมพันแบบฮ่องกง",
        INDO: "Indonesia Odds"
    },
    odds: {
        event: "รายการ",
        time: "เวลา",
        full_time: "เต็มเวลา",
        half_time: "ครึ่งแรก",
        title: "Odds",
        draw: "เสมอ",
        under: "ต่ำ",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "คี่",
            even: "คู่"
        },
        fglg: {
            first_goal: "ประตูแรก",
            last_goal: "ประตูท้าย",
            no_goal: "ไม่มีประตู",
        },
        hf: {
            home_home: "HH",
            home_draw: "HD",
            home_away: "HA",
            draw_home: "DH",
            draw_draw: "DD",
            draw_away: "DA",
            away_home: "AH",
            away_draw: "AD",
            away_away: "AA"
        },
        hfoe: {
            odd_odd: "คี่/คี่",
            odd_even: "คี่/คู่",
            even_odd: "คู่/คี่",
            even_even: "คู่/คู่"
        },
        single_line: {
            full_time_handicap: "เต็มเวลา แฮนดิแคป",
            half_time_handicap: "ครึ่งแรก แฮนดิแคป",
            handicap: 'HDP',
            h: "H",
            home: "ทีมเหย้า",
            a: "A",
            away: "ทีมเยือน",
            goal: "ประตู",
            over: "สูง",
            under: "ต่ำ"
        }
    },
    alerts: {
        no_matches: "ไม่มีการแข่งขันหรือเกมในขณะนี้ กรุณาเข้าชมการแข่งกีฬาหรือเกมอื่น ๆ แล้วกลับมาใหม่ภายหลัง"
    },
    league_filter: {
        leagues: "ลีกค์",
        title: "เลือกลีค",
        check_all: "ตรวจสอบทั้งหมด",
        close: "ปิด",
        submit: "Go",
        cancel: "ยกเลิก"
    },
    month: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "ธ.ค."
    },
    week: {
        mon: "จันทร์",
        tues: "อังคาร",
        web: "พุธ",
        thur: "พฤหัสบดี",
        fri: "ศุกร์",
        sat: "เสาร์",
        sun: "อาทิตย์",
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    },
    betlist: {
        name: "รายการ",
        bets: "เดิมพัน",
        waiting: "กำลังรอ",
        void: "ศูนย์",
        open: "ยอมรับการวางเดิมพันแล้ว",
        pending: "กำลังรอ",
        canceled: "ศูนย์",
        no_bets: "No Running Bet",
        no_waiting: "ไม่มีรายการเดิมพันที่รอการยอมรับ",
        no_void: "No Void Bet",
        header: {
            no: "เบอร์",
            date: "วันที่",
            choice: "รายการ",
            odds: "อัตราเดิมพัน",
            stake: "เงินที่วางเดิมพัน",
            status: "สถานะ"
        },
        from: "From",
        status: {
            void: "ศูนย์",
            open: "ยอมรับการวางเดิมพันแล้ว",
            pending: "กำลังรอ",
            canceled: "ศูนย์"
        }
    },
    statement: {
        name: "เสตทเม้น",
        date: "วันที่",
        remark: "ข้อสังเกตุ",
        turnover: "ยอดรวมของการเล่น",
        credit_debit: "ได้ / เสีย",
        commission: "คอมมิชชั่น",
        balance: "ยอดเงิน",
        opening_balance: "ยอดเงินที่เปิดบัญชี",
        betting_statement: "ย้อนดูรายการเดิมพันที่เล่นไปแล้ว",
        outstanding_bet: "รายการเดิมพันที่เล่นค้างไว้",
        bet_list: "ยอดเงิน",
        bet_list_no: "เบอร์",
        bet_list_transaction_time: "หมายเลขบิล",
        bet_list_event: "รายการ",
        bet_list_odds: "อัตราเดิมพัน",
        bet_list_stake: "เงินที่วางเดิมพัน",
        bet_list_win_loss: "ได้ / เสีย",
        bet_list_status: "สถานะ",
        bet_list_status_running: "ยอมรับการวางเดิมพันแล้ว",
        bet_list_subtotal: "จำนวนรวมย่อย (ชนะ)",
        bet_list_subtotal_commission: "จำนวนรวมย่อย (คอมมิชชั่น)",
        bet_list_total: "จำนวนรวม",
        bet_summary: "สรุปการเดิมพัน",
        bet_summary_date: "วันที่",
        bet_summary_bet_types: "ประเภทการเดิมพัน",
        bet_summary_event: "รายการ",
        bet_summary_stake: "เงินที่วางเดิมพัน",
        bet_summary_credit_debit: "ได้ / เสีย"
    },
    result: {
        title: "ผลลัพธ์",
        result: "ผลลัพธ์",
        today: "วันนี้",
        yesterday: "เมื่อวานนี้",
        all_leagues: "ทุกลีก",
        kickoff_time: "เวลาที่เริ่มแข่งขัน",
        match: "การแข่งขัน",
        first_half: "ผลการแข่งขันครึ่งแรก",
        final: "ผลจบการแข่งขัน",
        status: "สถานะ",
        outright: {
            text: "ทายผลผู้ชนะ",
            time: "เวลา",
            team: "Team",
            result: "ผลลัพธ์"
        }
    },
    settings: {
        change_account_password: "เปลี่ยนรหัสผ่านบัญชี",
        current_password: "กรุณายืนยันรหัสที่ใช้ปัจจุบัน",
        current_password_error: "กรุณากรอกรหัสผ่านปัจจุบัน",
        new_password: "รหัสผ่านใหม่",
        new_password_error: "กรุณากรอกรหัสผ่านใหม่",
        confirm_password: "ยืนยันรหัสในการเข้าระบบ",
        confirm_password_error: "รหัสผ่านไม่ตรงกัน",
        password_help: "รหัสผ่านต้องตรงตามตัวพิมพ์เล็ก/ใหญ่ และมีความยาวระหว่าง 6 ถึง 20 ตัวอักษร",
        change_account_nickname: "เปลี่ยนชื่อเล่นบัญชี",
        nickname: "ชื่อเล่น",
        nickname_error: "กรุณากรอกชื่อเล่น",
        nickname_help: "คุณสามารถตั้งชื่อเล่นสำหรับการแสดงผลและเข้าสู่ระบบ (ตัวอักษรหรือตัวเลข 5-20 ตัว) โปรดทราบว่า คุณตั้งชื่อเล่นนี้ได้ครั้งเดียวเท่านั้น คุณไม่สามารถแก้ไขหรือยกเลิกชื่อเล่นนี้เมื่อตั้งค่าแล้ว",
        submit: "ตกลง"
    },
    combos: {
        single: "เดี่ยว",
        double: "สองเท่า",
        treble: "สามเท่า",
        fold4: "4-เท่า",
        fold5: "5-เท่า",
        fold6: "6-เท่า",
        fold7: "7-เท่า",
        fold8: "8-เท่า",
        fold9: "9-เท่า",
        fold10: "10-เท่า",
        fold11: "11-เท่า",
        fold12: "12-เท่า",
        fold13: "13-เท่า",
        fold14: "14-เท่า",
        fold15: "15-เท่า",
        fold16: "16-เท่า",
        fold17: "17-เท่า",
        fold18: "18-เท่า",
        fold19: "19-เท่า",
        fold20: "20-เท่า"
    }
}