import { render, staticRenderFns } from "./Odds.vue?vue&type=template&id=a7293782&"
import script from "./Odds.vue?vue&type=script&lang=js&"
export * from "./Odds.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QBtn} from 'quasar'
component.options.components = Object.assign({QBtn}, component.options.components || {})
