//
//
//
//
//

export default {
  name: 'App',
  mounted () {
    if (this.isMobile()) {
      this.$router.replace('/m');
    }
  },
  methods: {
    isMobile () {
      let mobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return mobile;
    }
  }
}
