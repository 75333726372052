module.exports = {
    sb: {
        refresh: "Tải Lại",
        print: "In Trang Này",
        empty: "No information is available",
        note: {
            title: "Note:",
            content: [
                "The outstanding bets may take some time to process. Please go to Bet List to view your latest outstanding bets.",
                "The time above is displayed in GMT-4."
            ]
        },
        sorts: {
            league: "Lựa chọn bình thường",
            time: "Xếp Theo Thời Gian"
        },
        views: {
            single: "Một Dòng",
            double: "Dòng Kép"
        },
        my_favorites: "Yêu thích của tôi",
        my_favorites_empty: "Yêu thích của tôi trống. Vui lòng nhấn vào ngôi sao nằm phía trước trận đấu hoặc giải đấu để thêm vào đây.",
        parlay: "Cá cược tổng hợp",
        step: "Step",
        win: "Win",
        win_half: "Win Half",
        loss: "Loss",
        loss_half: "Loss Half",
        draw: "Draw",
        from: "From"
    },
    sportsbook: {
        name: "Wesports",
        early: "Sớm",
        today: "Hôm Nay",
        live: "Trực tiếp",
        parlay: "Cá Cược Tổng Hợp",
        step: "Step",
        all: "Tất Cả"
    },
    login: {
        login_header: "Đăng nhập",
        login_id: "Tên truy cập",
        login_id_error: "Please input your login Id",
        password: "Mật khẩu",
        password_error: 'Please input your password',
        password_help: "Mật khẩu có phân biệt chữ hoa, chữ thường và phải dài từ 6 đến 10 ký tự.",
        remember_me: "Remember Me?",
        accept: "I accept the license and terms",
        accept_error: "You need to accept the license and terms first",
        login: "Đăng nhập",
        logout: "Logout",
        reset_header: "Set your account",
        nickname: "biệt danh",
        nickname_error: "Vui lòng nhập biệt danh.",
        nickname_help: "Bạn có thể đặt biệt danh để hiển thị và đăng nhập (từ 5 đến 15 chữ cái hoặc số) Xin lưu ý, bạn chỉ có thể đặt biệt danh này một lần duy nhất. Bạn không thể thay đổi hoặc hủy biệt danh sau khi đã đặt.",
        new_password: "Mật khẩu mới",
        new_password_error: "Vui lòng nhập mật khẩu mới.",
        confirm_password: "Xác Nhận Mật Khẩu",
        confirm_password_error: "Các mật khẩu không khớp.",
        submit: "Gửi"
    },
    navs: {
        betlist: "Bảng Cược",
        statement: "Sao Kê",
        result: "Kết quả",
        settings: "Cài đặt"
    },
    user: {
        refresh: "Tải Lại",
        settings: "Cài đặt",
        show_balance: "Hiển thị số dư tài khoản",
        nickname: "Biệt danh",
        edit: "Chỉnh sửa",
        cash_balance: "Số dư",
        outstanding: "Chưa Xử Lý",
        net_position: "Net Position",
        bet_credit: "Hạn Mức",
        given_credit: "Hạn Mức Tín Dụng",
        last_login: "Truy Cập Gần Nhất",
        last_transaction: "Giao Dịch Gần Nhất",
        password_expiry: "Ngày Hết Hạn Củ"
    },
    betslip: {
        name: "Phiếu Đặt Cược",
        auto_refresh: "Tự làm mới",
        add_to_parlay: "Thêm vào Cược xiên",
        remove_from_parlay: "Remove from Parlay",
        accept_better_odds: "Tự nhận tỷ lệ cược tốt",
        stake: "Không",
        payout: "Thanh Toán",
        min: "Tối Thiểu",
        max: "Tối đa",
        process_bet: "Đặt Cược",
        cancel: "Hủy",
        confirm_bet: "Xác nhận đặt cược?",
        yes: "Có",
        no: "Không",
        min_stake_alert: "Tiền cược của bạn thấp hơn tiền cược tối thiểu.",
        no_bet_alert: "Phiếu cược của bạn trống, vui lòng nhấp vào tỷ lệ cược trong bảng để đưa ra lựa chọn cược của bạn.",
        single: "Đơn",
        parlay: "Cá cược tổng hợp",
        step: "Step",
        parlay_add: "Add to Parlay",
        parlay_remove: "Remove from Parlay",
        // Parlay
        accept_any_odds: "Tự động chấp nhận mọi tỷ lệ cược",
        expand_all: "Mở rộng tất cả",
        total_bets: "Tổng đặt cược",
        total_stake: "Tổng tiền đặt cược",
        max_payout: "Tiền thanh toán tối đa",
        step_add: "Add to Step",
        step_remove: "Remove from Step",
        close: "Close"
    },
    ticket: {
        id: "ID",
        stake: "Tiền cọc",
        payout: "Thanh Toán",
        stauts: "Trạng Thái",
        timestamp: "Thời Gian"
    },
    sports: {
        name: "Mọi môn thể thao",
        soccer: "Bóng đá",
        basketball: "Bóng rổ",
        early: "Sớm",
        today: "Hôm Nay",
        live: "Trực tiếp",
        no_matches: "No matches available",
        hdpou: "Kèo Chấp & Tài Xỉu",
        match_odds_1x2: "Tỷ lệ 1X2",
        correct_score: "Tỷ Số Chính Xác",
        odd_even: "Lẻ/Chẵn",
        total_goal: "Tổng Số Bàn Thắng",
        halftime_fulltime: "Hiệp 1/Toàn Thời Gian",
        halftime_fulltime_odd_even: "Chẳn / Lẻ của Nửa trận/Toàn",
        first_goal_lat_goal: "Bàn thắng Đầu/ Cuối",
        mix_parlay: "Cá Cược Tổng Hợp",
        step: "Step",
        outright: "Cược Thắng"
    },
    betgroups: {
        "1": "Kèo Chấp & Tài Xỉu",
        "2": "Tỷ lệ 1X2",
        "3": "Tỷ Số Chính Xác",
        "4": "Lẻ/Chẵn",
        "5": "Tổng Số Bàn Thắng",
        "6": "Hiệp 1/Toàn Thời Gian",
        "7": "Chẳn / Lẻ của Nửa trận/Toàn",
        "8": "Bàn thắng Đầu/ Cuối",
        "9": "Cá Cược Tổng Hợp",
        "10": "Step",
        "11": "Cược Thắng"
    },
    bettypes: {
        'full-time-handicap': "Toàn Thời Gian Handicap",
        'full-time-handicap-header': "Kèo Toàn Trận",
        'full-time-over/under': "Toàn Thời Gian Over/Under",
        'full-time-over/under-header': "Tài Xỉu Toàn Trận",
        'full-time-1x2': "Toàn Thời Gian 1X2",
        'full-time-1x2-header': "1X2 Toàn Trận",
        'half-time-handicap': "Hiệp 1 Handicap",
        'half-time-handicap-header': "Kèo Chấp Hiệp 1",
        'half-time-over/under': "Hiệp 1 Over/Under",
        'half-time-over/under-header': "Hiệp 1 - Tài/Xỉu",
        'half-time-1x2': "Hiệp 1 1X2",
        'half-time-1x2-header': "1X2 Hiệp 1"
    },
    odds_types: {
        DEC: "Decimal Odds",
        MY: "Malay Odds",
        HK: "Hong Kong Odds",
        INDO: "Indonesia Odds"
    },
    odds: {
        event: "Trận Đấu",
        time: "Giờ",
        full_time: "Toàn Thời Gian",
        half_time: "Hiệp 1",
        title: "Odds",
        draw: "Hòa",
        under: "u",
        cs: {
            AOS: "AOS"
        },
        oe: {
            odd: "Lẻ",
            even: "Chẵn"
        },
        fglg: {
            first_goal: "Bàn Thắng Đầu Tiên",
            last_goal: "Bàn Thắng Cuối Cùng",
            no_goal: "Không Có Bàn Thắng",
        },
        hf: {
            home_home: "HH",
            home_draw: "HD",
            home_away: "HA",
            draw_home: "DH",
            draw_draw: "DD",
            draw_away: "DA",
            away_home: "AH",
            away_draw: "AD",
            away_away: "AA"
        },
        hfoe: {
            odd_odd: "Lẻ/Lẻ",
            odd_even: "Lẻ/Chẵn",
            even_odd: "Chẵn/Lẻ",
            even_even: "Chẵn/Chẵn",
        },
        single_line: {
            full_time_handicap: "Cược Chấp Toàn Trận",
            half_time_handicap: "Hiệp 1 Cược Chấp",
            handicap: 'HDP',
            h: "Nhà",
            home: "Đội Nhà",
            a: "Khách",
            away: "Đội Khách",
            goal: "Trái",
            over: "Tài",
            under: "Xỉu"
        }
    },
    alerts: {
        no_matches: "Hiện không có trận đấu hay trò chơi nào, vui lòng truy cập các sự kiện thể thao hoặc trò chơi khác và quay trở lại sau."
    },
    league_filter: {
        leagues: "Giải",
        title: "Chọn Giải Đấu",
        check_all: "Chọn hết",
        close: "Đóng",
        submit: "Truy Cập",
        cancel: "Hủy"
    },
    month: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec"
    },
    week: {
        mon: "Th2",
        tues: "Th3",
        web: "Th4",
        thur: "Th5",
        fri: "Th6",
        sat: "Th7",
        sun: "CN",
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday'
    },
    betlist: {
        name: "Bet List",
        bets: "Đang Chạy",
        waiting: "Đang Chờ",
        void: "Hủy",
        open: "Đang Diễn Ra",
        pending: "Đang Chờ",
        canceled: "Hủy",
        no_bets: "Không có cược nào đang chạy",
        no_waiting: "Không có cược chưa xử lý",
        no_void: "Không Có Tiền Cược Bị Vô Hiệu Lực",
        header: {
            no: "Số.",
            date: "Ngày",
            choice: "Chọn",
            odds: "Tỷ lệ cược",
            stake: "Tiền cọc",
            status: "Trạng Thái"
        },
        from: "From",
        status: {
            waiting: "Đang Chờ",
            void: "Hủy",
            open: "Đang Diễn Ra",
            pending: "Đang Chờ",
            canceled: "Hủy"
        }
    },
    statement: {
        name: "Sao Kê",
        date: "Ngày",
        remark: "Ghi Chú",
        turnover: "Lượng tiền",
        credit_debit: "Tín Dụng / Ghi Nợ",
        commission: "Hoa Hồng",
        balance: "Số dư tài khoản",
        opening_balance: "Số Dư Tài Khoản",
        betting_statement: "Sao Kê",
        outstanding_bet: "Danh sách cược chưa xử lý",
        bet_list: "Bảng Cược",
        bet_list_no: "Số.",
        bet_list_transaction_time: "Thời Gian",
        bet_list_event: "Lựa Chọn",
        bet_list_odds: "Tỷ lệ cược",
        bet_list_stake: "Tiền cọc",
        bet_list_win_loss: "Thắng / Thua",
        bet_list_status: "Trạng Thái",
        bet_list_status_running: "Đang Diễn Ra",
        bet_list_subtotal: "Tổng phụ (Thắng)",
        bet_list_subtotal_commission: "Tổng phụ (Hoa Hồng)",
        bet_list_total: "Tổng cộng",
        bet_summary: "Tóm tắt cược",
        bet_summary_date: "Ngày",
        bet_summary_bet_types: "Loại Đặt Cược",
        bet_summary_event: "Lựa Chọn",
        bet_summary_stake: "Tiền cọc",
        bet_summary_credit_debit: "Tín Dụng / Ghi Nợ"
    },
    result: {
        title: "Kết Quả",
        result: "Kết Quả",
        today: "Hôm Nay",
        yesterday: "Hôm Qua",
        all_leagues: "Tất cả giải đấu",
        kickoff_time: "Thời Gian Bắt Đầu",
        match: "Trận Đấu",
        first_half: "Hiệp 1",
        final: "Chung Cuộc",
        status: "Trạng Thái",
        outright: {
            text: "Cược Thắng",
            time: "Giờ",
            team: "Đội",
            result: "Kết Quả"
        }
    },
    settings: {
        change_account_password: "Thay đổi mật khẩu tài khoản",
        current_password: "Vui lòng xác nhận mật khẩu hiện thời của bạn",
        current_password_error: "Vui lòng nhập mật khẩu hiện tại",
        new_password: "Mật khẩu mới",
        new_password_error: "Vui lòng nhập mật khẩu mới",
        confirm_password: "Xác Nhận Mật Khẩu",
        confirm_password_error: "Các mật khẩu không khớp.",
        password_help: "Mật khẩu có phân biệt chữ hoa, chữ thường và phải dài từ 6 đến 20 ký tự.",
        change_account_nickname: "Thay đổi biệt danh tài khoản",
        nickname: "biệt danh",
        nickname_error: "Vui lòng nhập biệt danh",
        nickname_help: "Bạn có thể đặt biệt danh để hiển thị và đăng nhập (từ 5 đến 20 chữ cái hoặc số) Xin lưu ý, bạn chỉ có thể đặt biệt danh này một lần duy nhất. Bạn không thể thay đổi hoặc hủy biệt danh sau khi đã đặt.",
        submit: "Gửi"
    },
    combos: {
        single: "Cược đơn",
        double: "Cược đôi",
        treble: "Cược ba",
        fold4: "4-Kết hợp",
        fold5: "5-Kết hợp",
        fold6: "6-Kết hợp",
        fold7: "7-Kết hợp",
        fold8: "8-Kết hợp",
        fold9: "9-Kết hợp",
        fold10: "10-Kết hợp",
        fold11: "11-Kết hợp",
        fold12: "12-Kết hợp",
        fold13: "13-Kết hợp",
        fold14: "14-Kết hợp",
        fold15: "15-Kết hợp",
        fold16: "16-Kết hợp",
        fold17: "17-Kết hợp",
        fold18: "18-Kết hợp",
        fold19: "19-Kết hợp",
        fold20: "20-Kết hợp"
    }
}