//
//
//

export default {
  name: 'Clock',
  data () {
    return {
      clock: null,
      time: null
    }
  },
  mounted () {
    this.$store.dispatch('serverTime').then(data => {
      this.time = data.time;
      this.clock = setInterval(() => this.time++, 1000)
    })
  },
  destroyed () {
    if (this.clock) clearInterval(this.clock)
  }
}
