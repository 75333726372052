//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Betslip',
  props: {
    value: Object
  },
  data () {
    return {
      tab: 'process',
      addToParlay: false,
      addToStep: false,
      stake: this.$store.state.stake,
      expandedBets: false,
      submitting: false,
      loading: false,
      refresh: {
        auto: true,
        countDown: 9,
        timer: null
      },
      expanded: {}
    }
  },
  mounted () {
    this.$watch('expandedBets', (newVal) => {
      if (newVal) {
        var obj = {}
        this.value.bets.map(bet => bet.combo).forEach(element => obj[element] = true)
        this.expanded = obj
      } else {
        this.expanded = {}
      }
    }, { immediate: true })

    this.$watch('value.selections', (newVal) => {
      console.log({ 'value.selections': newVal })
      if (newVal.length > 2) {
        this.loading = true
        this.$store.dispatch('getTicket', { type: this.value.type, selections: newVal })
          .then(ticket => {
            this.value = Object.assign(this.value, {
              bets: ticket.bets,
              minBet: ticket.minBet,
              maxBet: ticket.maxBet,
              total: ticket.total,
              stake: ticket.stake,
              payout: ticket.payout
            })
            this.loading = false

            // this.$nextTick(() => this.$refs.parlayStakeInput[0].focus())
          })
          .catch(err => {
            this.loading = false
            this.$q.notify({
              color: 'red-5',
              textColor: 'white',
              icon: 'info',
              position: 'top',
              message: err.data
            })
          })
      }

      // if (newVal.length > 0) {
      //   this.startRefresh()
      // } else {
      //   this.stopRefresh()
      // }
    }, { immediate: true })

    this.$watch('refresh.auto', (auto) => {
      if (auto) {
        this.startRefresh()
      } else {
        this.stopRefresh()
      }
    }, { immediate: true })

    this.$watch("addToParlay", (newVal, oldVal) => {
      if (oldVal !== undefined && this.value) {
        let selection = this.value.selections[0]
        var parlay = this.$store.state.betslip2.parlay
        if (newVal) {
          parlay = this.putSelection(parlay, selection)
        }
        else {
          parlay = this.removeSelection(parlay, selection)
        }

        this.$store.commit('betslip2', { parlay })
      }
    }, { immediate: true });

    this.$watch("addToStep", (newVal, oldVal) => {
      if (oldVal !== undefined && this.value) {
        let selection = this.value.selections[0]
        var step = this.$store.state.betslip2.step
        if (newVal) {
          step = this.putSelection(step, selection);
        }
        else {
          step = this.removeSelection(step, selection);
        }

        this.$store.commit('betslip2', { step })
      }
    }, { immediate: true })

    this.$watch('stake', (newVal) => {
      if (newVal != null) {
        if (this.isSingle()) {
          let total = 1
          let stake = Math.min(parseFloat(newVal), this.value.maxBet)
          let odds = this.selection.oddsType === 'DEC' ? this.selection.odds : (Math.abs(this.selection.odds) + 1)
          let payout = parseFloat((stake * odds).toFixed(2))
          this.value = Object.assign(this.value, { total, stake, payout })
        } else {
          this.value.bets.forEach(bet => {
            bet.stake = Math.min(newVal, bet.maxBet)
          })


          //  var entry = { total: 0, stake: 0, payout: 0 }
          //   ticket.bets.forEach(bet => {
          //     bet.stake = Math.min(newVal, bet.maxBet)
          //     if (bet.stake) {
          //       entry.total += bet.count
          //       entry.stake += bet.stake * bet.count
          //       entry.payout += parseFloat((bet.stake * bet.odds).toFixed(2))
          //     }
          //   })
          //   this.current.ticket = Object.assign(this.current.ticket, entry)
        }
        this.$forceUpdate()
        this.$store.commit('stake', newVal)
      }
    }, { immediate: true })

    if (this.isSingle()) {
      this.addToParlay = this.checked(this.$store.state.betslip2.parlay, this.value.selections[0])
      this.addToStep = this.checked(this.$store.state.betslip2.step, this.value.selections[0])
    }
  },
  computed: {
    selection () {
      return this.value.selections[0]
    },
    currency: function () {
      return this.$store.state.user.currency
    },
    inChecking () {
      return this.$store.state.betChecking;
    }
  },
  methods: {
    onProcess () {
      if (this.value.state === 0) {
        this.$q.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'info',
          position: 'top',
          message: this.$t('betslip.stake_error')
        })
      } else {
        this.tab = 'confirm'
      }
    },
    onCancel () {
      this.value = { type: this.value.type, selections: [] }
      this.onClose()
    },
    onYes () {
      this.submitting = true
      let ticket = this.value
      this.$store.dispatch("betting", ticket)
        .then(result => {
          ticket = Object.assign(ticket, {
            id: result.id,
            type: result.type,
            stake: result.stake,
            payout: result.payout,
            status: result.status,
            stakeBonus: result.stakeBonus,
            timestamp: result.timestamp,
            message: result.message
          })
          // reload user
          this.$store.dispatch("getUser")
          this.submitting = false
          this.tab = 'completed'

          this.$q.notify({
            color: 'teal',
            textColor: 'white',
            icon: 'tag_faces',
            position: 'top',
            message: ticket.message
          })
        })
        .catch(err => {
          this.submitting = false;
          this.$q.notify({
            color: 'red-5',
            textColor: 'white',
            icon: 'info',
            position: 'top',
            message: err.message
          })

          if (err.oddsChange) {
            this.stake = err.stake
            this.value = Object.assign(this.value, { stake: err.stake, payout: err.payout, selections: [err.selection] })
            this.tab = 'process'
          }
        })
    },
    onNo () {
      this.tab = "process"
    },
    onClose () {
      this.$emit('close')
    },
    checked: function (ticket, selection) {
      console.log(ticket)
      return ticket !== null && ticket.selections
        .findIndex(s => s.marketId === selection.marketId && s.stakeNumber === selection.stakeNumber) > -1
    },
    isSingle: function () {
      return this.value.type === 1
    },
    putSelection: function (ticket, selection) {
      if (ticket) {
        let selections = ticket.selections
        let i = selections.findIndex(s => s.eventId === selection.eventId);

        if (i == -1) {
          ticket.selections.push(selection);
        }
        else {
          ticket.selections = selections
            .slice(0, i)
            .concat([selection])
            .concat(selections.slice(i + 1, selections.length));
        }
      }
      else {
        ticket = { selections: [selection] }
      }

      return ticket
    },
    removeSelection: function (ticket, selection) {
      if (ticket) {
        let selections = ticket.selections;
        let i = selections.findIndex(s => s.marketId === selection.marketId && s.stakeNumber === selection.stakeNumber);

        if (i > -1) {
          ticket.selections = selections
            .slice(0, i)
            .concat(selections.slice(i + 1, selections.length));
        }
      }

      return ticket
    },
    inputStake: function () {
      var entry = { total: 0, stake: 0, payout: 0 }
      this.value.bets
        .filter(bet => bet.stake > 0)
        .forEach(bet => {
          let stake = Math.min(parseFloat(bet.stake), bet.maxBet)
          entry.total += bet.count
          entry.stake += stake * bet.count
          entry.payout += stake * bet.odds
        })
      console.log(entry)
      this.value = Object.assign(this.value, entry)
      this.$forceUpdate()
    },
    startRefresh: function () {
      // if (this.refresh.auto) {
      //   this.refresh.countDown = 9
      //   if (!this.refresh.timer) {
      //     this.refresh.timer = setInterval(() => {
      //       if (this.refresh.countDown > 0) {
      //         this.refresh.countDown--

      //         if (this.refresh.countDown === 0) {
      //           this.submitting = true;
      //           let ticket = this.value
      //           this.$store.dispatch('getTicket', ticket).then(data => {
      //             let stake = this.value.stake
      //             this.vlaue = Object.assign(data, { payout: stake * data.payout, stake: stake })
      //             this.submitting = false
      //             this.refresh.countDown = 9
      //           })
      //         }
      //       }
      //     }, 1000)
      //   }
      // }
    },
    stopRefresh: function () {
      if (this.refresh.timer) {
        clearInterval(this.refresh.timer)
        this.refresh = Object.assign(this.refresh, { timer: null, countDown: 9 })
      }
    }
  }
}
