//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "Match",
  props: {
    type: Number,
    startTime: Number,
    homeId: Number,
    homeName: String,
    homeScore: Number,
    homeRedcard: Number,
    awayId: Number,
    awayName: String,
    awayScore: Number,
    awayRedcard: Number,
    live: Boolean,
    ractTime: String,
    handicap: Number
  },
  computed: {
    today: function () {
      return this.type === 2
    },
    early: function () {
      return this.type === 3
    }
  }
}
