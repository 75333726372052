//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Login from '../../components/Login'
export default {
  name: 'LayoutDefault',
  components: {
    Login
  },
  data () {
    return {
      loginOpen: this.$store.state.loggedOn ? 'yes' : 'no',
      leftDrawerOpen: false,
      sportsbook: 2,  // Today
      sports: [],
      submiting: false
    }
  },
  mounted () {
    this.$watch('$store.state.sb.current', (newVal) => {
      if (newVal) {
        this.sportsbook = newVal === 'early' ? 3 : (newVal === 'live' ? 1 : 2)
        this.setSports(newVal, this.$store.state.sb.sports)
      }
    })

    this.$watch('$store.state.sb.sports', (newVal) => {
      if (newVal) {
        this.setSports(this.$store.state.sb.current, newVal)
      }
    })

    this.setSports(this.$store.state.sb.current, this.$store.state.sb.sports)
  },
  computed: {
    user () {
      return this.$store.state.user;
    },
    userAvatar () {
      return this.user.loginId.substring(0, 1).toUpperCase()
    }
  },
  methods: {
    async logout () {
      this.submiting = true
      this.$store.dispatch('logout').then(() => {
        this.submiting = false
        this.loginOpen = "no"
      })
    },
    toAccount () {
      this.$router.push('/m/account')
    },
    loginSuccess () {
      this.$store.dispatch('getUser')
      this.$store.dispatch('getFavorites')
      this.loginOpen = "yes"
    },
    setSports (current, sports) {
      let i = current === 'early' ? 0 : (current === 'live' ? 2 : 1)
      this.sports = sports[i] ? sports[i].ss : []
    }
  }
}
