//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { isEmpty } from "../utils";

export default {
  name: "sportCard",
  props: {
    sports: Array,
    sportsbook: {
      type: Number,
      default: 1
    },
    sportId: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      selectedSports: []
    };
  },
  computed: {
    active: function () {
      return this.$store.state.active === "all-sports"
    }
  },
  methods: {
    activeSport (sportsbook, sportId) {
      return this.sportsbook === sportsbook && this.sportId === sportId;
    },
    gotoLive (sport) {
      if (isEmpty(this.selectedSports)) {
        this.$router.push({ name: "Sports", params: { sportsbook: 1 } });
      } else {
        // this.$router.push({ name: 'Live', params: { sportsbook: 1, sports: this.selectedSports.join(",") }})
        this.$router.push({
          name: "Hdpou",
          params: {
            sportsbook: 1,
            sportId: sport.id,
            betTypes: sport.bgs[0].items
          }
        });
      }
    },
    changeSportsbook (type) {
      // Live
      if (type.id === 1) {
        // In default selected all live sports
        let live = this.sports.filter(v => v.id === 1).map(type => type.ss);
        if (live.length > 0) {
          this.selectedSports = live.map(sport => sport[0].id);
        }

        if (type.ss.length > 0) {
          this.gotoLive(type.ss[0]);
        }
      } else if (type.ss.length > 0) {
        this.$router.push({
          name: "Hdpou",
          params: {
            sportsbook: type.id,
            sportId: type.ss[0].id,
            betTypes: type.ss[0].bgs[0].items
          }
        })
      }
    },
    clickSport (sport) {
      var checked = true;
      for (var i = 0; i < this.selectedSports.length; i++) {
        if (this.selectedSports[i] === sport.id) {
          checked = false;
          this.selectedSports.splice(i, 1);
          i--;
        }
      }

      if (checked) {
        this.selectedSports.push(sport.id);
      }

      this.gotoLive(sport);
    }
  }
};
