//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "tabs-layout"
};
